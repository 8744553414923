import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles, CircularProgress, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '25%',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    marginTop: '2rem',
  },
}));

const LevelLoading = (): ReactElement => {
  const { t } = useTranslation('common');
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h3" component="h3">
        {t('LoadingLevel')}
      </Typography>
      <CircularProgress className={classes.loader} size={72} />
    </div>
  );
};

export default LevelLoading;
