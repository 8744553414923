import React, { FunctionComponent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FormControl,
  FormControlLabel,
  Radio,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  RadioGroup,
  Typography,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';

import StandalonePointBadge from 'components/StandalonePointBadge';

import {
  IQuestionAnswer,
  IQuestionState,
  IThing,
} from '@sonoran-ecosystems/types';

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  submitContainer: {
    position: 'relative',
  },
  loadingContainer: {
    position: 'absolute',
    left: '-6.5rem',
    top: '50%',
    transform: 'translate(0, -50%)',
  },
  badgeContainer: {
    position: 'absolute',
    left: '-3.5rem',
    top: '50%',
    transform: 'translate(0, -50%)',
  },
  mediaContainer: {
    padding: '1rem',
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '1rem',
    flexWrap: 'nowrap',
  },
  media: {
    height: '5.5rem',
    width: '8.25rem',
    objectFit: 'cover',
    objectPosition: '50% 50%',
    borderRadius: '16px',
  },
  title: {
    marginLeft: '1rem',
    flex: 1,
  },
}));

export interface Props {
  thing?: IThing | null;
  value?: IQuestionState | null;
  loading?: boolean;
  onGuess: (guess: IQuestionAnswer) => Promise<void>;
}

const QuestionDialog: FunctionComponent<Props> = ({
  thing,
  value,
  onGuess,
  loading,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['common']);
  const [guessText, setGuessText] = useState<string>('');

  const handleAnswer = useCallback(async () => {
    if (!guessText) {
      return;
    }

    const guess = value?.answers.find((answer) => answer.text === guessText);
    if (!guess) {
      return;
    }

    await onGuess(guess);
    setGuessText('');
  }, [value, guessText, onGuess]);

  return (
    <Dialog
      open={Boolean(value)}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth
      aria-labelledby="question-dialog-title"
    >
      <div className={classes.mediaContainer}>
        {thing && (
          <img className={classes.media} src={thing.src} alt={thing.name} />
        )}
        <Typography
          className={classes.title}
          variant="h6"
          id="question-dialog-title"
        >
          {value?.text}
        </Typography>
      </div>
      <DialogContent>
        <FormControl component="fieldset">
          <RadioGroup
            name="answers"
            value={guessText}
            onChange={(event) => setGuessText(event.target.value)}
          >
            {value &&
              value.answers.map((answer) => (
                <FormControlLabel
                  key={answer.text}
                  value={answer.text}
                  disabled={answer.attempted || false}
                  control={<Radio />}
                  label={answer.text}
                />
              ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <div className={classes.submitContainer}>
          {loading && (
            <div className={classes.loadingContainer}>
              <CircularProgress size={24} />
            </div>
          )}
          {value?.points && (
            <div className={classes.badgeContainer}>
              <StandalonePointBadge points={value?.points} />
            </div>
          )}
          <Button
            disabled={loading || !guessText}
            onClick={handleAnswer}
            variant="contained"
          >
            {t('submit')}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default QuestionDialog;
