import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/styles';

import { Typography, Grid } from '@material-ui/core';
import { auth, database } from 'firebaseApp';

const useStyles = makeStyles(() => ({
  text: {
    textAlign: 'center',
    wordBreak: 'break-all',
  },
}));

const StatisticsContent: FunctionComponent = () => {
  const { t } = useTranslation(['common', 'level3']);
  const classes = useStyles();
  const [gradeNumerator, setGradeNumerator] = useState();
  const [gradeDenominator, setGradeDenominator] = useState();
  const [teachersNotes, setTeachersNotes] = useState();

  useEffect(() => {
    const uid = auth.currentUser?.uid;
    if (!uid) {
      return undefined;
    }

    database
      .collection('students')
      .doc(uid)
      .collection('submissions')
      .orderBy('created', 'desc')
      .limit(1)
      .get()
      .then((res) => {
        const data = res.docs[0].data();
        if (!data) {
          return res;
        }
        setGradeNumerator(data.gradeNumerator);
        setGradeDenominator(data.gradeDenominator);
        setTeachersNotes(data.teachersNotes);
        return res;
      })
      .catch((err) => {
        throw new Error(err);
      });

    return undefined;
  }, []);

  return (
    <Grid container spacing={3} alignItems="center" justify="center">
      <Grid item xs={6}>
        <Typography variant="h6" className={classes.text}>
          {t('Grade')}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" className={classes.text}>
          {gradeDenominator && (gradeNumerator || gradeNumerator === 0)
            ? `${gradeNumerator || 0}/${gradeDenominator || ''}`
            : t('N/A')}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" className={classes.text}>
          {t('level3:TeachersNotes')}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" className={classes.text}>
          {teachersNotes || t('N/A')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default StatisticsContent;
