import React, { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import {
  makeStyles,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Divider,
} from '@material-ui/core';

import Saguaro from 'assets/saguaro.jpg';
import CloseIconButton from 'components/IconButtons/Close';

import ThingCard from '../ThingCard';
import EmptyTile from '../EmptyTile';
import LevelToolbar from '../LevelToolbar';
import StandalonePointBadge from '../StandalonePointBadge';
import ScrollView from '../ScrollView';

const useStyles = makeStyles(() => ({
  root: {
    width: '100px',
    height: '175px',
  },
  headerRoot: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  divider: {
    marginTop: '0.5rem',
    width: '40px',
  },
  dividerRoot: {
    backgroundColor: '#C4C4C4',
  },
  boxComponentContainer: {
    minWidth: '150px',
    display: 'flex',
    justifyContent: 'center',
  },
  actions: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  loading: {
    marginRight: '0.5rem',
  },
}));

export interface Props {
  open: boolean;
  level: number;
  onClose: () => void;
  title: ReactNode;
}

const SupportDialog: FunctionComponent<Props> = ({
  open,
  level,
  onClose,
  title,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('support');

  const pointContent = (
    <>
      <Box pb={3}>
        <Box pb={1} width="150px">
          <Typography align="center" variant="h6">
            {t('ProgressBar')}
          </Typography>
        </Box>
        <Box>
          <Box display="flex">
            <Box
              mr={3}
              alignItems="center"
              className={classes.boxComponentContainer}
            >
              <LevelToolbar
                supportDialog
                points={600}
                maxPoints={2000}
                unlockPoints={1600}
                displayOnly
              />
            </Box>
            <Box>
              <Typography>{t('ProgressBarSupport')}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box pb={4}>
        <Box pb={1} width="150px">
          <Typography align="center" variant="h6">
            {t('PointBadge')}
          </Typography>
        </Box>
        <Box>
          <Box display="flex" alignItems="center">
            <Box mr={3} className={classes.boxComponentContainer}>
              <StandalonePointBadge points={100} />
            </Box>
            <Box>
              <Typography>{t('PointBadgeSupport')}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );

  let content = null;
  if (level === 1) {
    content = (
      <Box mr={3}>
        <Box pb={3}>
          <Box pb={1} width="150px">
            <Typography align="center" variant="h6">
              How to Play
            </Typography>
          </Box>
          <Box>
            <Box display="flex" alignItems="center">
              <Box mr={3} className={classes.boxComponentContainer}>
                <ThingCard
                  small
                  name="Saguaro"
                  src={Saguaro}
                  points={null}
                  disableMotion
                />
              </Box>
              <Box>
                <Typography>{t('ThingCardSupport')}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        {pointContent}
      </Box>
    );
  } else if (level === 2) {
    content = (
      <Box mr={3}>
        <Box pb={2}>
          <Box pb={1} width="150px">
            <Typography align="center" variant="h6">
              {t('HowToPlay')}
            </Typography>
          </Box>
          <Box>
            <Box display="flex" alignItems="center">
              <Box mr={3} className={classes.boxComponentContainer}>
                <ScrollView
                  className={classes.root}
                  header={
                    <div className={classes.headerRoot}>
                      <Typography align="center" variant="body1" component="h4">
                        {t('Organisms')}
                      </Typography>
                      <div className={classes.divider}>
                        <Divider
                          classes={{
                            root: classes.dividerRoot,
                          }}
                        />
                      </div>
                    </div>
                  }
                >
                  <div />
                </ScrollView>
              </Box>
              <Box>
                <Typography>{t('ScrollViewSupport')}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box pb={2}>
          <Box>
            <Box display="flex" alignItems="center">
              <Box mr={3} className={classes.boxComponentContainer}>
                <EmptyTile points={null} canPlace />
              </Box>
              <Box>
                <Typography>{t('EmptyTileSupport')}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box pb={4}>
          <Box>
            <Box display="flex" alignItems="center">
              <Box mr={3} className={classes.boxComponentContainer}>
                <ThingCard
                  small
                  name="Saguaro"
                  src={Saguaro}
                  points={null}
                  disableMotion
                />
              </Box>
              <Box>
                <Typography>{t('ThingCardPlacedSupport')}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        {pointContent}
      </Box>
    );
  } else {
    content = (
      <Box mr={3}>
        <Box pb={3}>
          <Box pb={1} width="150px">
            <Typography align="center" variant="h6">
              {t('HowToPlay')}
            </Typography>
          </Box>
          <Box pb={2}>
            <Box display="flex" alignItems="center">
              <Box mr={3} className={classes.boxComponentContainer}>
                <ScrollView
                  className={classes.root}
                  header={
                    <div className={classes.headerRoot}>
                      <Typography align="center" variant="body1" component="h4">
                        {t('Organisms')}
                      </Typography>
                      <div className={classes.divider}>
                        <Divider
                          classes={{
                            root: classes.dividerRoot,
                          }}
                        />
                      </div>
                    </div>
                  }
                >
                  <div />
                </ScrollView>
              </Box>
              <Box>
                <Typography>{t('ThingCardSupport')}</Typography>
              </Box>
            </Box>
          </Box>
          <Box pb={2}>
            <Box>
              <Box display="flex" alignItems="center">
                <Box mr={3} className={classes.boxComponentContainer}>
                  <EmptyTile points={null} canPlace />
                </Box>
                <Box>
                  <Typography>{t('EmptyTileSupport')}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box display="flex" alignItems="center">
              <Box mr={3} className={classes.boxComponentContainer}>
                <ThingCard
                  small
                  name="Saguaro"
                  src={Saguaro}
                  points={null}
                  disableMotion
                />
              </Box>
              <Box>
                <Typography>{t('ThingCardPlacedSupport')}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      disablePortal
      style={{ position: 'absolute' }}
    >
      <DialogTitle disableTypography>
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5">{title}</Typography>
          <CloseIconButton onClick={onClose} />
        </Box>
      </DialogTitle>
      <DialogContent>
        {content}
        <div />
      </DialogContent>
    </Dialog>
  );
};

export default SupportDialog;
