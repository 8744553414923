import React, { FunctionComponent } from 'react';

import useSound from 'use-sound';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@material-ui/core';
import { SummaryIcon } from 'utils/iconMappings';

export interface Props {
  className?: string | undefined;
  onClick?: () => void;
}

const SummaryIconButton: FunctionComponent<Props> = ({
  className,
  onClick,
}: Props) => {
  const { t } = useTranslation('common');
  const [play] = useSound(`${process.env.PUBLIC_URL}/sounds/click.mp3`, {
    interrupt: true,
  });

  function handleClick(): void {
    if (onClick) {
      play();
      onClick();
    }
  }

  return (
    <Tooltip title={`${t('Summary')}`}>
      <IconButton className={className} onClick={handleClick} color="primary">
        <SummaryIcon />
      </IconButton>
    </Tooltip>
  );
};

export default SummaryIconButton;
