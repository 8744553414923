import { FunctionComponent } from 'react';

import { Draggable } from 'react-beautiful-dnd';

import ThingCard from 'components/ThingCard';

export interface Props {
  className?: string;
  id: string;
  index: number;
  name: string;
  src: string;
  points: number | null;
  isDragDisabled?: boolean | undefined;
}

const DraggableThingCard: FunctionComponent<Props> = ({
  className,
  id,
  index,
  name,
  src,
  points,
  isDragDisabled,
}) => (
  <Draggable draggableId={id} index={index} isDragDisabled={isDragDisabled}>
    {(provided) => (
      <ThingCard
        className={className}
        name={name}
        src={src}
        points={points}
        draggableProvided={provided}
        disableMotion={isDragDisabled}
      />
    )}
  </Draggable>
);

export default DraggableThingCard;
