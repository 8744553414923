import React, {
  FunctionComponent,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { makeStyles, Typography } from '@material-ui/core';
import { Droppable } from 'react-beautiful-dnd';

// Utils
import { log } from 'utils/log';

// Hooks
import useLevelState from 'hooks/useLevelState';

// Components
import AchievementsDialog from 'components/Achievements/DialogWithListener';
import Level1ScrollView from 'components/Level1/ScrollView';
import DraggableThingCard from 'components/DraggableThingCard';
import LevelToolbar from 'components/LevelToolbar';
import LevelToolbarTutorial from 'components/LevelToolbarTutorial';
import QuestionDialog from 'components/QuestionDialog';
import IntroductionDialog from 'components/IntroductionDialog';
import SummaryDialog from 'components/Level1/SummaryDialog';
import HomeIconButton from 'components/IconButtons/Home';
import SummaryIconButton from 'components/IconButtons/Summary';
import FeedbackMessage from 'components/FeedbackMessage';
import SupportDialog from 'components/SupportDialog';
import LevelLoading from 'components/LevelLoading';

// Types
import { IQuestionAnswer } from '@sonoran-ecosystems/types';
import Level1Status from 'types/enums/LevelStatus';

const useStyles = makeStyles(() => ({
  categorized: {
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
  },
  root: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    // padding: '6rem',
    position: 'absolute',
    width: '1200px',
    '@media (min-width: 1920px)': {
      width: '1850px',
    },
  },
  toolbarContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '2rem',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  activeThingDroppable: {
    width: '9.25rem',
  },
  iconButton: {
    marginLeft: '1rem',
  },
  activeThingContainer: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  activeThingHelp: {
    marginTop: '2rem',
    padding: '0.75rem',
    borderRadius: '0.5rem',
    background: 'rgba(0, 0, 0, 0.52)',
    // backdropFilter: 'blur(8px)',
    color: 'white',
  },
}));

const Level1: FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation(['level1']);
  const prevFirstTryCount = useRef<number>(0);
  const [openAchievementsDialog, setOpenAchievementsDialog] = useState(false);
  const [guessingAnswer, setGuessingAnswer] = useState(false);
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState<string>('');
  const [activeLevelToolbarTutorial, setActiveLevelToolbarTutorial] = useState(
    false,
  );
  const [openFeedbackMessage, setOpenFeedbackMessage] = useState(false);
  const [openIntroductionDialog, setOpenIntroductionDialog] = useState(false);
  const [openSummaryDialog, setOpenSummaryDialog] = useState(false);
  const [openSummaryDialogTriggered, setOpenSummaryDialogTriggered] = useState(
    false,
  );
  const [openSupportDialog, setOpenSupportDialog] = useState(false);
  const {
    level1State,
    restartLevel,
    initializeLevel,
    currentLevel,
    currentLevelInitialized,
    setCurrentLevel,
    guessAnswer,
  } = useLevelState();

  useEffect(() => {
    if (currentLevel !== 1) {
      setCurrentLevel(1);
    }

    if (currentLevelInitialized) {
      return;
    }

    initializeLevel().catch(log);
  }, [currentLevel, initializeLevel, currentLevelInitialized, setCurrentLevel]);

  useEffect(() => {
    // Handle First Try feedback messages
    if (!level1State?.firstTries) {
      return;
    }

    if (level1State.firstTries === prevFirstTryCount.current + 1) {
      prevFirstTryCount.current = level1State.firstTries;
      setFeedbackMessage('First Try!');
      setOpenFeedbackMessage(true);
    }
  }, [level1State]);

  useEffect(() => {
    // reset state if new level
    if (level1State?.status === Level1Status.START) {
      prevFirstTryCount.current = level1State.firstTries;
      setOpenIntroductionDialog(true);
      setOpenSummaryDialog(false);
      setOpenSummaryDialogTriggered(false);
    }
  }, [level1State]);

  useEffect(() => {
    if (
      level1State?.status !== Level1Status.GAMEOVER ||
      openSummaryDialogTriggered
    ) {
      return;
    }

    setOpenSummaryDialog(true);
    setOpenSummaryDialogTriggered(true);
  }, [level1State, openSummaryDialogTriggered]);

  const handleGuessAnswer = useCallback(
    async (guess: IQuestionAnswer) => {
      setGuessingAnswer(true);
      await guessAnswer(guess);
      setGuessingAnswer(false);
    },
    [guessAnswer],
  );

  const handleRestart = useCallback(async () => {
    setSummaryLoading(true);
    await restartLevel();
    setSummaryLoading(false);
  }, [restartLevel]);

  const handleCloseIntroduction = useCallback(() => {
    setOpenIntroductionDialog(false);
    setActiveLevelToolbarTutorial(true);
  }, []);

  const handleDismissLevelToolbarTutorial = useCallback(() => {
    setActiveLevelToolbarTutorial(false);
  }, []);

  const handleSummaryClick = useCallback(() => {
    if (level1State?.status !== Level1Status.GAMEOVER) {
      return;
    }

    setOpenSummaryDialog(true);
  }, [level1State]);

  const handleHomeClick = useCallback(() => {
    history.push('/select-level');
  }, [history]);

  const handleAchievementClick = useCallback(() => {
    setOpenAchievementsDialog(true);
  }, []);

  const handleSupportClick = useCallback(() => {
    setOpenSupportDialog(true);
  }, []);

  if (!currentLevelInitialized) {
    return <LevelLoading />;
  }

  if (!level1State) {
    return <></>;
  }

  const {
    status,
    living,
    nonliving,
    activeThing,
    points,
    maxPoints,
    unlockPoints,
    activeQuestion,
  } = level1State;

  return (
    <>
      <div className={classes.root}>
        <div className={classes.toolbarContainer}>
          <div className={classes.buttonContainer}>
            <HomeIconButton onClick={handleHomeClick} />
            {status === Level1Status.GAMEOVER && (
              <SummaryIconButton
                className={classes.iconButton}
                onClick={handleSummaryClick}
              />
            )}
          </div>
          <LevelToolbarTutorial
            active={activeLevelToolbarTutorial}
            onDismiss={handleDismissLevelToolbarTutorial}
          >
            <LevelToolbar
              points={points}
              maxPoints={maxPoints}
              unlockPoints={unlockPoints}
              onAchievementClick={handleAchievementClick}
              onSupportClick={handleSupportClick}
            />
          </LevelToolbarTutorial>
        </div>
        <div className={classes.content}>
          <Level1ScrollView living>
            {living.map((thing, index) => (
              <DraggableThingCard
                className={classes.categorized}
                key={thing.id}
                id={thing.id}
                index={index}
                name={thing.name}
                src={thing.src}
                points={thing.points}
                isDragDisabled
              />
            ))}
          </Level1ScrollView>
          <div className={classes.activeThingContainer}>
            <Droppable droppableId="level1ActiveThing">
              {(provided) => (
                <div
                  className={classes.activeThingDroppable}
                  // eslint-disable-next-line @typescript-eslint/unbound-method
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {activeThing && !activeQuestion && (
                    <DraggableThingCard
                      key={activeThing.id}
                      id={activeThing.id}
                      index={0}
                      name={activeThing.name}
                      src={activeThing.src}
                      points={activeThing.points}
                    />
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {activeThing && (
              <div className={classes.activeThingHelp}>
                <Typography variant="h6" component="h6">
                  {t('DragLeftOrRight')}
                </Typography>
              </div>
            )}
          </div>
          <Level1ScrollView living={false}>
            {nonliving.map((thing, index) => (
              <DraggableThingCard
                className={classes.categorized}
                key={thing.id}
                id={thing.id}
                index={index}
                name={thing.name}
                src={thing.src}
                points={thing.points}
                isDragDisabled
              />
            ))}
          </Level1ScrollView>
        </div>
        <QuestionDialog
          thing={activeThing}
          value={activeQuestion}
          loading={guessingAnswer}
          onGuess={handleGuessAnswer}
        />
        <IntroductionDialog
          title={t('IntroductionTitle')}
          content={t('IntroductionContent')}
          open={openIntroductionDialog}
          onClose={handleCloseIntroduction}
        />
        <SummaryDialog
          open={openSummaryDialog}
          loading={summaryLoading}
          onClose={() => setOpenSummaryDialog(false)}
          onRestart={handleRestart}
        />
        <FeedbackMessage
          message={feedbackMessage}
          open={openFeedbackMessage}
          onClose={() => setOpenFeedbackMessage(false)}
        />
        <AchievementsDialog
          open={openAchievementsDialog}
          onClose={() => setOpenAchievementsDialog(false)}
        />
        <SupportDialog
          open={openSupportDialog}
          level={1}
          onClose={() => setOpenSupportDialog(false)}
          title="Help"
        />
      </div>
    </>
  );
};

export default Level1;
