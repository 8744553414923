import { useCallback, useState, ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import {
  makeStyles,
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogTitle,
  Button,
  CircularProgress,
  Typography,
} from '@material-ui/core';

import { ConnectionType } from '@sonoran-ecosystems/types';
import IConnectionState from 'types/interfaces/IConnectionState';

const useStyles = makeStyles(() => ({
  root: {},
  imagesContainer: {
    marginBottom: '2rem',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  imageContainer: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  image: {
    marginBottom: '1rem',
    objectFit: 'cover',
    objectPosition: '50% 50%',
    borderRadius: '16px',
    width: '5.5rem',
    height: '5.5rem',
  },
  marginLeft: {
    marginLeft: '4rem',
  },
  actions: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  submitContainer: {
    position: 'relative',
  },
  loadingContainer: {
    position: 'absolute',
    left: '-6.5rem',
    top: '50%',
    transform: 'translate(0, -50%)',
  },
}));

export interface Props {
  question?: IConnectionState;
  onGuess: (type: ConnectionType) => Promise<void>;
}

function isConnectionType(value: unknown): value is ConnectionType {
  return Object.values(ConnectionType).includes(value as ConnectionType);
}

const ConnectionQuestionDialog = ({
  question,
  onGuess,
}: Props): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation('connectionQuestion');
  const [loading, setLoading] = useState(false);
  const [guessType, setGuessType] = useState<ConnectionType | string>('');

  const handleSubmit = useCallback(async () => {
    if (!isConnectionType(guessType)) {
      return;
    }

    setLoading(true);
    await onGuess(guessType);
    setGuessType('');
    setLoading(false);
  }, [onGuess, guessType]);

  return (
    <Dialog
      open={Boolean(question)}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{t('Title')}</DialogTitle>
      <DialogContent>
        {question && (
          <div className={classes.imagesContainer}>
            <div className={classes.imageContainer}>
              <img
                className={classes.image}
                src={question.species[0].src}
                alt={question.species[0].id}
              />
              <Typography align="center">{question.species[0].name}</Typography>
            </div>
            <div className={clsx(classes.imageContainer, classes.marginLeft)}>
              <img
                className={classes.image}
                src={question.species[1].src}
                alt={question.species[1].id}
              />
              <Typography align="center">{question.species[1].name}</Typography>
            </div>
          </div>
        )}
        <FormControl component="fieldset">
          <RadioGroup
            name="answers"
            value={guessType}
            onChange={(event) =>
              setGuessType(event.target.value as ConnectionType)
            }
          >
            {Object.values(ConnectionType).map((type) => (
              <FormControlLabel
                key={type}
                value={type}
                disabled={Boolean(
                  question?.attemptedTypes
                    ? question.attemptedTypes.includes(type)
                    : false,
                )}
                control={<Radio />}
                label={t(type)}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <div className={classes.submitContainer}>
          {loading && (
            <div className={classes.loadingContainer}>
              <CircularProgress size={24} />
            </div>
          )}
          <Button
            disabled={loading || !guessType}
            onClick={handleSubmit}
            variant="contained"
          >
            {t('submit')}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ConnectionQuestionDialog;
