import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography, makeStyles } from '@material-ui/core';
import { Forward } from '@material-ui/icons';

import SubmitIconButton from 'components/IconButtons/Submit';
import SummaryIconButton from 'components/IconButtons/Summary';

import TutorialDialog from 'components/TutorialDialog';
import ThingCard from 'components/ThingCard';
import EmptyTile from 'components/EmptyTile';
import HarrisHawk from 'assets/harris-hawk.jpg';
import ConnectTileImage from 'assets/connectTile.jpg';
import DisconnectTileImage from 'assets/disconnectTile.jpg';

export interface Props {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  connectImg: {
    height: '100px',
    borderRadius: '10px',
  },
  disconnectImg: {
    height: '100px',
    borderRadius: '10px',
  },
  forwardIcon: {
    color: '#1D4961',
    width: '100px',
    height: '100%',
  },
}));

const ContentStep0: FunctionComponent = () => {
  const { t } = useTranslation(['level3']);
  return (
    <Box>
      <Typography>
        For the final level you will be creating another web but this time you
        get to tell us which species are connected.
      </Typography>
      <Box mt={2}>
        <Typography>
          Start by filling in the empty tiles with whatever species you want.
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" mt={2}>
        <ThingCard
          small
          name="Harris Hawk"
          src={HarrisHawk}
          points={null}
          disableMotion
        />
        <Box ml={4}>
          <Typography>
            Just like in level 2 you can click the species picture to learn more
            about them.
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" mt={2}>
        <EmptyTile points={null} canPlace />
        <Box ml={4}>
          <Typography>{t('PlaceTutorial')}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

const ContentStep1: FunctionComponent = () => {
  const { t } = useTranslation(['level3']);
  const classes = useStyles();

  return (
    <Box>
      <Box mt={2}>
        <Typography>
          When at least 2 species are placed on the web you can start making
          connections.
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography>{t('ConnectTutorial')}</Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
        mt={2}
      >
        <img src={ConnectTileImage} alt="" className={classes.connectImg} />
        <Forward className={classes.forwardIcon} />
        <img
          src={DisconnectTileImage}
          alt=""
          className={classes.disconnectImg}
        />
      </Box>
      <Box mt={2}>
        <Typography>{t('DisconnectTutorial')}</Typography>
      </Box>
    </Box>
  );
};

const ContentStep2: FunctionComponent = () => (
  <Box>
    <Box mt={2}>
      <Typography>
        When you have filled your web with all the species and you are happy
        with your species connections, then you can submit your web to your
        teacher.
      </Typography>
    </Box>
    <Box display="flex" alignItems="center" mt={2}>
      <Box display="flex" justifyContent="center" width="100px">
        <SubmitIconButton />
      </Box>
      <Typography>
        Click on this button to submit your web to your teacher.
      </Typography>
    </Box>
    <Box mt={2}>
      <Typography>
        After your teacher has finished grading your web you will be able to
        view your grade for this level.
      </Typography>
    </Box>
    <Box display="flex" alignItems="center" mt={2}>
      <Box display="flex" justifyContent="center" width="100px">
        <SummaryIconButton />
      </Box>
      <Typography>Click on this button to see your grade.</Typography>
    </Box>
    <Box mt={2}>
      <Typography>
        You will be able to edit and resubmit your web after getting a grade
        from your teacher.
      </Typography>
    </Box>
  </Box>
);

const Level3TutorialDialog: FunctionComponent<Props> = ({ open, onClose }) => {
  const { t } = useTranslation(['level3']);

  return (
    <TutorialDialog
      title={t('Level3Tutorial')}
      open={open}
      onClose={onClose}
      ContentSteps={[
        <ContentStep0 key={0} />,
        <ContentStep1 key={1} />,
        <ContentStep2 key={2} />,
      ]}
    />
  );
};

export default Level3TutorialDialog;
