import React, { ReactElement, Suspense } from 'react';

import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';

// Pages
import SelectLogin from 'pages/menus/SelectLogin';
import SelectLevel from 'pages/menus/SelectLevel';
import StudentLogin from 'pages/menus/StudentLogin';
import Level1 from 'pages/levels/Level1';
import Level2 from 'pages/levels/Level2';
import Level3 from 'pages/levels/Level3';

// Providers
import { LevelStateProvider } from 'hooks/useLevelState';
import DragDropContainer from 'components/DragDropContainer';

// Components
import LevelBackground from 'components/LevelBackground';
import PrivateRoute from 'components/PrivateRoute';
import AuthRedirectRoute from 'components/AuthRedirectRoute';

import theme from './theme';

const App = (): ReactElement => (
  <>
    <Helmet>
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700;800;900&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    <LevelStateProvider>
      <DragDropContainer>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Suspense fallback="loading">
            <Router>
              <Switch>
                <AuthRedirectRoute path="/login/select">
                  <SelectLogin />
                </AuthRedirectRoute>
                <AuthRedirectRoute path="/login/student">
                  <StudentLogin />
                </AuthRedirectRoute>
                <PrivateRoute path="/select-level">
                  <SelectLevel />
                </PrivateRoute>
                <PrivateRoute path="/levels/1">
                  <LevelBackground level={1}>
                    <Level1 />
                  </LevelBackground>
                </PrivateRoute>
                <PrivateRoute path="/levels/2">
                  <LevelBackground level={2}>
                    <Level2 />
                  </LevelBackground>
                </PrivateRoute>
                <PrivateRoute path="/levels/3">
                  <LevelBackground level={3}>
                    <Level3 />
                  </LevelBackground>
                </PrivateRoute>
                <PrivateRoute exact path="/">
                  <Redirect to={{ pathname: '/select-level' }} />
                </PrivateRoute>
              </Switch>
            </Router>
          </Suspense>
        </ThemeProvider>
      </DragDropContainer>
    </LevelStateProvider>
  </>
);

export default App;
