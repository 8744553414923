import {
  FunctionComponent,
  useRef,
  useEffect,
  useState,
  useCallback,
} from 'react';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import { makeStyles, Slide } from '@material-ui/core';
import LineTo from 'react-lineto';

import useLevelState from 'hooks/useLevelState';
import { ITile } from 'hooks/useLevelState/LevelStateProvider';

import AchievementsDialog from 'components/Achievements/DialogWithListener';
import SummaryDialog from 'components/Level2/SummaryDialog';
import TutorialDialog from 'components/Level2/TutorialDialog';
import IntroductionDialog from 'components/IntroductionDialog';
import FeedbackMessage from 'components/FeedbackMessage';
import SupportDialog from 'components/SupportDialog';
import HomeIconButton from 'components/IconButtons/Home';
import LevelToolbar from 'components/LevelToolbar';
import OrganismDrawer from 'components/OrganismDrawer';
import OrganismBank from 'components/OrganismBank';
import ThingCard from 'components/ThingCard';
import EmptyTile from 'components/EmptyTile';
import SummaryIconButton from 'components/IconButtons/Summary';
import LevelLoading from 'components/LevelLoading';

// Types
import ILivingThing from 'types/interfaces/ILivingThing';
import LevelStatus from 'types/enums/LevelStatus';

// Utils
import { log } from 'utils/log';
import ConnectionQuestionDialog from 'components/ConnectionQuestionDialog';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
  },
  toolbarContainer: {
    position: 'absolute',
    top: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '2rem',
    zIndex: 1201,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconButton: {
    marginLeft: '1rem',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    maxWidth: '1200px',
    '@media (min-width: 1920px)': {
      maxWidth: '1700px',
    },
  },
  notPlaced: {
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
  },
  circleContainer: {
    marginTop: '-45px',
    marginLeft: '50px',
    width: '45rem',
    height: '45rem',
    position: 'relative',
    '@media (min-width: 1920px)': {
      marginTop: '50px',
      marginLeft: '220px',
      width: '50rem',
      height: '50rem',
    },
  },
  drawerContainer: {
    marginTop: '40px',
  },
  lineFilled: {},
  lineNotFilled: {},
  tile: {
    display: 'block',
    position: 'absolute',
    top: '50%',
    left: '50%',
    margin: '-3.125rem',
    zIndex: 1,
  },
  tile0: {
    transform: 'translate(16rem)',
    '@media (min-width: 1920px)': {
      transform: 'translate(20rem)',
    },
  },
  tile1: {
    transform: 'rotate(50deg) translate(16rem) rotate(-50deg)',
    '@media (min-width: 1920px)': {
      transform: 'rotate(50deg) translate(20rem) rotate(-50deg)',
    },
  },
  tile2: {
    transform: 'rotate(100deg) translate(16rem) rotate(-100deg)',
    '@media (min-width: 1920px)': {
      transform: 'rotate(100deg) translate(20rem) rotate(-100deg)',
    },
  },
  tile3: {
    transform: 'rotate(150deg) translate(16rem) rotate(-150deg)',
    '@media (min-width: 1920px)': {
      transform: 'rotate(150deg) translate(20rem) rotate(-150deg)',
    },
  },
  tile4: {
    transform: 'rotate(200deg) translate(16rem) rotate(-200deg)',
    '@media (min-width: 1920px)': {
      transform: 'rotate(200deg) translate(20rem) rotate(-200deg)',
    },
  },
  tile5: {
    transform: 'rotate(250deg) translate(16rem) rotate(-250deg)',
    '@media (min-width: 1920px)': {
      transform: 'rotate(250deg) translate(20rem) rotate(-250deg)',
    },
  },
  tile6: {
    transform: 'rotate(305deg) translate(16rem) rotate(-305deg)',
    '@media (min-width: 1920px)': {
      transform: 'rotate(305deg) translate(20rem) rotate(-305deg)',
    },
  },
  line: {
    transform: 'translate(16rem)',
  },
  hidden: {
    display: 'none',
  },
}));

const Level2: FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation('level2');
  // if provided, this number is the tile id being attempted
  const [attemptingTilePlace, setAttemptingTilePlace] = useState<number>();
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [openAchievementsDialog, setOpenAchievementsDialog] = useState(false);
  const [openIntroductionDialog, setOpenIntroductionDialog] = useState(false);
  const [openTutorialDialog, setOpenTutorialDialog] = useState(false);
  const [openSummaryDialog, setOpenSummaryDialog] = useState(false);
  const [openSummaryDialogTriggered, setOpenSummaryDialogTriggered] = useState(
    false,
  );
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [openFeedbackMessage, setOpenFeedbackMessage] = useState(false);
  const [openSupportDialog, setOpenSupportDialog] = useState(false);
  const [organism, setOrganism] = useState<ILivingThing | null>(null);
  const [canPlace, setCanPlace] = useState(false);
  const [drawerActive, setDrawerActive] = useState(false);
  const [bankActive, setBankActive] = useState(true);
  const {
    level2State,
    restartLevel,
    initializeLevel,
    currentLevel,
    currentLevelInitialized,
    setCurrentLevel,
    placeOrganismInTile,
    guessConnectionType,
  } = useLevelState();

  const prevFirstTryCount = useRef<number>(level2State?.firstTries || 0);

  useEffect(() => {
    if (currentLevel !== 2) {
      setCurrentLevel(2);
    }

    initializeLevel().catch(log);
  }, [currentLevel, setCurrentLevel, initializeLevel]);

  const handleCloseDrawer = useCallback(() => {
    setCanPlace(false);
    setDrawerActive(false);
  }, []);

  useEffect(() => {
    if (!canPlace || !organism || !level2State) {
      return;
    }

    if (level2State.notPlaced.find((item) => item.id === organism.id)) {
      return;
    }

    handleCloseDrawer();
  }, [handleCloseDrawer, canPlace, level2State, organism, bankActive]);

  useEffect(() => {
    // Handle First Try feedback messages
    if (!level2State?.firstTries) {
      return;
    }

    if (level2State.firstTries === prevFirstTryCount.current + 1) {
      prevFirstTryCount.current = level2State.firstTries;
      setFeedbackMessage('First Try!');
      setOpenFeedbackMessage(true);
    }
  }, [level2State]);

  useEffect(() => {
    // reset state if new level
    if (level2State?.status === LevelStatus.START) {
      prevFirstTryCount.current = level2State.firstTries;
      setOpenIntroductionDialog(true);
      setOpenSummaryDialog(false);
      setOpenSummaryDialogTriggered(false);
    }
  }, [level2State]);

  useEffect(() => {
    if (
      level2State?.status !== LevelStatus.GAMEOVER ||
      openSummaryDialogTriggered
    ) {
      return;
    }

    setOpenSummaryDialog(true);
    setOpenSummaryDialogTriggered(true);
  }, [level2State, openSummaryDialogTriggered]);

  const handleRestart = useCallback(async () => {
    setSummaryLoading(true);
    await restartLevel();
    setSummaryLoading(false);
  }, [restartLevel]);

  const handleAchievementClick = useCallback(() => {
    setOpenAchievementsDialog(true);
  }, []);

  const handleSupportClick = useCallback(() => {
    setOpenSupportDialog(true);
  }, []);

  const handleHomeClick = useCallback(() => {
    history.push('/select-level');
  }, [history]);

  const handleCloseIntroduction = useCallback(() => {
    setOpenIntroductionDialog(false);
    setOpenTutorialDialog(true);
  }, []);

  const handleCloseTutorial = useCallback(() => {
    setOpenTutorialDialog(false);
  }, []);

  const handleClickNotPlaced = useCallback((nextOrganism: ILivingThing) => {
    setCanPlace(true);
    setBankActive(false);
    setOrganism(nextOrganism);
  }, []);

  const handleClickPlaced = useCallback((tile: ITile) => {
    setBankActive(false);
    setOrganism(tile.placedOrganism);
  }, []);

  const handleBankExited = useCallback(() => {
    setDrawerActive(true);
  }, []);

  const handleDrawerExited = useCallback(() => {
    setBankActive(true);

    // if you remove this, then the place button will not show on the next drawer open for a non placed
    // because a useEffect is there to check when to close the drawer if you place the not placed
    // and without this it creates a "race condition" (it doesn't actually race, it always messes up)
    setOrganism(null);
  }, []);

  const handleSummaryClick = useCallback(() => {
    if (level2State?.status !== LevelStatus.GAMEOVER) {
      return;
    }

    setOpenSummaryDialog(true);
  }, [level2State]);

  const handlePlace = useCallback(
    async (tileId: number) => {
      if (!organism || !tileId) {
        return;
      }

      setAttemptingTilePlace(tileId);
      await placeOrganismInTile(organism.id, tileId);
      setAttemptingTilePlace(undefined);
    },
    [organism, placeOrganismInTile],
  );

  function getTileClassname(id: number): string | undefined {
    switch (id) {
      case 0:
        return classes.tile0;
      case 1:
        return classes.tile1;
      case 2:
        return classes.tile2;
      case 3:
        return classes.tile3;
      case 4:
        return classes.tile4;
      case 5:
        return classes.tile5;
      case 6:
        return classes.tile6;
      default:
        return undefined;
    }
  }

  if (!currentLevelInitialized) {
    return <LevelLoading />;
  }

  if (!level2State) {
    return <></>;
  }

  const {
    points,
    maxPoints,
    unlockPoints,
    notPlaced,
    tileConnections,
    tiles,
    status,
    connectionTypeQuestion,
  } = level2State;

  return (
    <>
      <div className={classes.root}>
        <div className={classes.toolbarContainer}>
          <div className={classes.buttonContainer}>
            <HomeIconButton onClick={handleHomeClick} />
            {status === LevelStatus.GAMEOVER && (
              <SummaryIconButton
                className={classes.iconButton}
                onClick={handleSummaryClick}
              />
            )}
          </div>
          <LevelToolbar
            points={points}
            maxPoints={maxPoints}
            unlockPoints={unlockPoints}
            onAchievementClick={handleAchievementClick}
            onSupportClick={handleSupportClick}
          />
        </div>
        <div className={classes.content}>
          <div className={classes.circleContainer}>
            {tileConnections.map((connection) => {
              const from = getTileClassname(connection.indexes[0]);
              const to = getTileClassname(connection.indexes[1]);

              if (!from || !to) {
                return <></>;
              }

              const anchors = connection.indexes.map((value) => {
                const anchor = [];
                // handle left or right
                if ([0, 1, 6].includes(value)) {
                  anchor.push('left');
                } else if ([2, 5].includes(value)) {
                  anchor.push('center');
                } else {
                  anchor.push('right');
                }

                // handle top or bottom
                if ([5, 6].includes(value)) {
                  anchor.push('bottom');
                } else if ([1, 2, 3].includes(value)) {
                  anchor.push('top');
                } else {
                  anchor.push('middle');
                }

                return anchor.join(' ');
              });

              return (
                <LineTo
                  delay
                  fromAnchor={anchors[0]}
                  toAnchor={anchors[1]}
                  borderWidth={4}
                  borderStyle={connection.filled ? undefined : 'dashed'}
                  borderColor={connection.filled ? 'black' : 'black'}
                  key={connection.indexes.join(':')}
                  from={from}
                  to={to}
                  zIndex={0}
                  within={classes.circleContainer}
                />
              );
            })}
            {tiles.map((tile) => {
              const className = getTileClassname(tile.id);

              if (!tile.placedOrganism) {
                let disabled =
                  canPlace &&
                  organism &&
                  tile.attemptedOrganisms.includes(organism.id);
                if (!disabled) {
                  disabled =
                    attemptingTilePlace || attemptingTilePlace === 0
                      ? attemptingTilePlace !== tile.id
                      : false;
                }

                return (
                  <div
                    key={tile.id}
                    className={clsx(
                      className,
                      classes.tile,
                      tile.possibleOrganisms?.length === 0 && classes.hidden,
                    )}
                  >
                    <EmptyTile
                      points={tile.pointsAvailable}
                      canPlace={canPlace}
                      disabled={Boolean(disabled)}
                      onPlace={() => handlePlace(tile.id)}
                    />
                  </div>
                );
              }

              return (
                <div key={tile.id} className={clsx(className, classes.tile)}>
                  <ThingCard
                    small
                    points={null}
                    name={tile.placedOrganism.name}
                    src={tile.placedOrganism.src}
                    onClick={() => handleClickPlaced(tile)}
                  />
                </div>
              );
            })}
          </div>
          <div className={classes.drawerContainer}>
            <Slide
              direction="left"
              in={bankActive}
              mountOnEnter
              unmountOnExit
              onExited={handleBankExited}
            >
              <OrganismBank>
                {notPlaced.map((notPlacedOrganism) => (
                  <ThingCard
                    points={null}
                    className={classes.notPlaced}
                    key={notPlacedOrganism.id}
                    name={notPlacedOrganism.name}
                    src={notPlacedOrganism.src}
                    onClick={() => handleClickNotPlaced(notPlacedOrganism)}
                  />
                ))}
              </OrganismBank>
            </Slide>
            <Slide
              direction="left"
              in={drawerActive}
              mountOnEnter
              unmountOnExit
              onExited={handleDrawerExited}
            >
              <OrganismDrawer organism={organism} onClose={handleCloseDrawer} />
            </Slide>
          </div>
        </div>
        <IntroductionDialog
          title={t('IntroductionTitle')}
          content={t('IntroductionContent')}
          open={openIntroductionDialog}
          onClose={handleCloseIntroduction}
        />
        <SummaryDialog
          open={openSummaryDialog}
          loading={summaryLoading}
          onClose={() => setOpenSummaryDialog(false)}
          onRestart={handleRestart}
        />
        <FeedbackMessage
          message={feedbackMessage}
          open={openFeedbackMessage}
          onClose={() => setOpenFeedbackMessage(false)}
        />
        <AchievementsDialog
          open={openAchievementsDialog}
          onClose={() => setOpenAchievementsDialog(false)}
        />
        <SupportDialog
          open={openSupportDialog}
          level={2}
          onClose={() => setOpenSupportDialog(false)}
          title="Help"
        />
        <ConnectionQuestionDialog
          question={connectionTypeQuestion}
          onGuess={guessConnectionType}
        />
        <TutorialDialog
          open={openTutorialDialog}
          onClose={handleCloseTutorial}
        />
      </div>
    </>
  );
};

export default Level2;
