import { ComponentProps, ReactElement } from 'react';

import { Route, Redirect } from 'react-router-dom';

import { auth } from 'firebaseApp';

const PrivateRoute = ({
  children,
  ...props
}: ComponentProps<typeof Route>): ReactElement => (
  <Route
    {...props}
    render={({ location }) => {
      if (!auth.currentUser) {
        return children;
      }

      return (
        <Redirect
          to={{ pathname: '/select-level', state: { from: location } }}
        />
      );
    }}
  />
);

export default PrivateRoute;
