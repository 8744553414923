import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography, makeStyles } from '@material-ui/core';
import { CheckCircle, Cancel, Forward } from '@material-ui/icons';

import TutorialDialog from 'components/TutorialDialog';
import ThingCard from 'components/ThingCard';
import EmptyTile from 'components/EmptyTile';
import HarrisHawk from 'assets/harris-hawk.jpg';
import EmptyTileImage from 'assets/emptyTile.jpg';
import FilledTileImage from 'assets/filledTile.jpg';

export interface Props {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  icon: {
    width: '60px',
    height: '100%',
    margin: '26px',
  },
  correctIcon: {
    color: 'green',
  },
  wrongIcon: {
    color: 'red',
  },
  emptyImg: {
    height: '175px',
    borderRadius: '10px',
  },
  filledImg: {
    height: '175px',
    borderRadius: '10px',
  },
  forwardIcon: {
    color: '#1D4961',
    width: '100px',
    height: '100%',
  },
}));

const ContentStep0: FunctionComponent = () => (
  <Box>
    <Typography>
      In this level we are going to create a web to show how different plants
      and animals are connected with each other!
    </Typography>
    <Box mt={2}>
      <Typography>
        Connections are shown on the web with a dotted or solid line.
      </Typography>
    </Box>
    <Box mt={2} display="flex" alignItems="center">
      <Box
        minWidth="100px"
        height="1px"
        border="3px dashed black"
        ml={1}
        mr={4}
      />
      <Box>
        <Typography>
          Dotted lines are connections that we still need to find.
        </Typography>
      </Box>
    </Box>
    <Box mt={2} display="flex" alignItems="center">
      <Box
        minWidth="100px"
        height="1px"
        border="3px solid black"
        ml={1}
        mr={4}
      />
      <Box>
        <Typography>Solid lines are connections that we have found.</Typography>
      </Box>
    </Box>
    <Box mt={2}>
      <Typography>
        To start finding connections we need to learn a little bit about the
        species.
      </Typography>
    </Box>
    <Box display="flex" alignItems="center" mt={2}>
      <ThingCard
        small
        name="Harris Hawk"
        src={HarrisHawk}
        points={null}
        disableMotion
      />
      <Box ml={4}>
        <Typography>
          Start by clicking on a species tile to learn more about them.
        </Typography>
      </Box>
    </Box>
  </Box>
);
const ContentStep1: FunctionComponent = () => {
  const { t } = useTranslation(['level2']);
  const classes = useStyles();

  return (
    <Box>
      <Box mt={2}>
        <Typography>
          Each species will give us a couple facts about them and a hint about
          their connections in the ecosystem.
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" mt={2}>
        <EmptyTile points={null} canPlace />
        <Box ml={4}>
          <Typography>{t('PlaceTutorial')}</Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" mt={2}>
        <Box>
          <CheckCircle className={`${classes.icon} ${classes.correctIcon}`} />
        </Box>
        <Box ml={4}>
          <Typography>
            Correct answers will reward you with 100 points and ask you how the
            species are connected.
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" mt={2}>
        <Box display="flex" justifyContent="center">
          <Cancel className={`${classes.icon} ${classes.wrongIcon}`} />
        </Box>
        <Box ml={4}>
          <Typography>
            Wrong answers will lose you 10 points and give you another chance to
            guess the correct location.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const ContentStep2: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Box>
      <Box mt={2}>
        <Typography>
          When a species is placed on the web some new connections may show up.
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
        mt={2}
      >
        <img src={EmptyTileImage} alt="" className={classes.emptyImg} />
        <Forward className={classes.forwardIcon} />
        <img src={FilledTileImage} alt="" className={classes.filledImg} />
      </Box>
      <Box mt={2}>
        <Typography>
          The level is complete when all the species have been placed on the
          web.
        </Typography>
      </Box>
    </Box>
  );
};

const Level2TutorialDialog: FunctionComponent<Props> = ({ open, onClose }) => {
  const { t } = useTranslation(['level2']);

  return (
    <TutorialDialog
      title={t('Level2Tutorial')}
      open={open}
      onClose={onClose}
      ContentSteps={[
        <ContentStep0 key={0} />,
        <ContentStep1 key={1} />,
        <ContentStep2 key={2} />,
      ]}
    />
  );
};

export default Level2TutorialDialog;
