import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  button: {
    marginLeft: '1rem',
  },
}));

export interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const SubmitToTeacherConfirmationDialog: FunctionComponent<Props> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['level3']);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('SubmitToTeacher')}</DialogTitle>
      <DialogContent>
        <Typography>{t('ConfirmSubmitToTeacher')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          onClick={onSubmit}
        >
          {t('submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubmitToTeacherConfirmationDialog;
