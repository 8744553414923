import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from '@material-ui/core';

import {
  AchievementName,
  UserAchievement,
  AchievementStatus,
  IAchievement,
} from '@sonoran-ecosystems/types';

import CloseIconButton from 'components/IconButtons/Close';

import AchievementCard from '../Card';

const useStyles = makeStyles(() => ({
  titleContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  achievementCardContainer: {
    margin: '1rem',
  },
}));

export interface Props {
  userAchievements: UserAchievement[];
  achievements: IAchievement[];
  onClaimAchievement: (id: AchievementName) => Promise<void>;
  open: boolean;
  onClose: () => void;
}

const AchievementsDialog = ({
  userAchievements,
  achievements,
  onClaimAchievement,
  open,
  onClose,
}: Props): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation('achievement');
  const [claiming, setClaiming] = useState<AchievementName | undefined>(
    undefined,
  );

  async function handleClaim(id: AchievementName): Promise<void> {
    setClaiming(id);
    await onClaimAchievement(id);
    setClaiming(undefined);
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      disablePortal
      style={{ position: 'absolute' }}
    >
      <DialogTitle disableTypography>
        <div className={classes.titleContainer}>
          <Typography variant="h4">{t('Achievements')}</Typography>
          <CloseIconButton onClick={onClose} />
        </div>
      </DialogTitle>
      <DialogContent>
        {achievements.map((achievement) => {
          let progress = 0;
          let status = AchievementStatus.INPROGRESS;
          const userAchievement = userAchievements.find(
            (item) => item.id === achievement.id,
          );
          if (userAchievement) {
            status = userAchievement.status;

            if (achievement.steps && userAchievement.stepsCompleted) {
              progress = Math.floor(
                (userAchievement.stepsCompleted * 100) / achievement.steps,
              );
            }
          }

          return (
            <div
              key={achievement.id}
              className={classes.achievementCardContainer}
            >
              <AchievementCard
                key={achievement.id}
                claiming={claiming === achievement.id}
                disabled={claiming ? claiming !== achievement.id : undefined}
                title={achievement.title}
                subtitle={achievement.subtitle}
                status={status}
                progress={progress}
                onClaim={() => handleClaim(achievement.id)}
              >
                <Typography>
                  {t('PricklyPearsWithValue', { value: achievement.reward })}
                </Typography>
              </AchievementCard>
            </div>
          );
        })}
      </DialogContent>
    </Dialog>
  );
};

export default AchievementsDialog;
