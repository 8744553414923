import {
  FunctionComponent,
  createContext,
  useState,
  useCallback,
  ReactNode,
} from 'react';

import { useImmer } from 'use-immer';
import { plainToClass } from 'class-transformer';

// utils
import { log } from 'utils/log';

// firebase
import { functions, storage } from 'firebaseApp';

// data
import data from 'data/level';

// shared types
import {
  PlaceIntoLivingEventDTO,
  PlaceIntoNonlivingEventDTO,
  PlaceOrganismInTileEventDTO,
  ConnectTilesEventDTO,
  DisconnectTilesEventDTO,
  RemoveOrganismFromTileEventDTO,
  SubmitToTeacherEventDTO,
  GuessAnswerEventDTO,
  IQuestionState,
  IQuestionAnswer,
  IThing,
  Level1StateDTO,
  LevelStatus,
  ILivingThing,
  ThingStateDTO,
  Level2StateDTO,
  TileConnectionStateDTO,
  Level3StateDTO,
  ConnectionType,
  GuessConnectionTypeEventDTO,
} from '@sonoran-ecosystems/types';
import IConnectionState, {
  IConnectionSpecies,
} from 'types/interfaces/IConnectionState';

export interface IThingState extends IThing {
  src: string;
  living: boolean;
  points: number | null;
  question: IQuestionState;
  attempted: boolean | null;
}

export interface ITile {
  id: number;
  pointsAvailable: number | null;
  placedOrganism: ILivingThing | null;
  possibleOrganisms: string[];
  attemptedOrganisms: string[];
}

export interface ILevel1State {
  status: LevelStatus;
  firstTries: number;
  living: IThingState[];
  nonliving: IThingState[];
  uncategorized: IThingState[];
  activeThing?: IThingState;
  activeQuestion?: IQuestionState;
  points: number;
  maxPoints: number;
  unlockPoints: number;
}

export interface ILevel2State {
  status: LevelStatus;
  points: number;
  maxPoints: number;
  unlockPoints: number;
  firstTries: number;
  notPlaced: ILivingThing[];
  tiles: ITile[];
  tileConnections: TileConnectionStateDTO[];
  connectionTypeQuestion?: IConnectionState;
}

export interface ILevel3State {
  canSubmit: boolean;
  disabled: boolean;
  status: LevelStatus;
  notPlaced: ILivingThing[];
  tiles: ITile[];
  tileConnections: TileConnectionStateDTO[];
  submitted: boolean;
}

export interface ILevelStateContext {
  currentLevel?: 1 | 2 | 3;
  currentLevelInitialized?: boolean;
  level1State?: ILevel1State;
  level2State?: ILevel2State;
  level3State?: ILevel3State;
  destroy: () => void;
  setCurrentLevel: (level: 1 | 2 | 3) => void;
  initializeLevel: () => Promise<void>;
  restartLevel: (level?: 1 | 2 | 3) => Promise<void>;
  guessAnswer: (guess: IQuestionAnswer) => Promise<void>;
  placeThingIntoLiving: (index?: number) => void;
  placeThingIntoNonliving: (index?: number) => void;
  placeOrganismInTile: (organismId: string, tileId: number) => Promise<void>;
  guessConnectionType: (type: ConnectionType) => Promise<void>;
  level3PlaceOrganismInTile: (
    organismId: string,
    tileId: number,
  ) => Promise<void>;
  level3RemoveOrganismFromTile: (tileId: number) => Promise<void>;
  level3ConnectTiles: (tileId0: number, tileId1: number) => Promise<void>;
  level3DisconnectTiles: (tileId0: number, tileId1: number) => Promise<void>;
  level3SubmitToTeacher: () => Promise<void>;
}

export const LevelStateContext = createContext<ILevelStateContext>({
  currentLevel: undefined,
  level1State: undefined,
  level2State: undefined,
  level3State: undefined,
  setCurrentLevel: () => undefined,
  destroy: () => undefined,
  initializeLevel: () => Promise.resolve(),
  restartLevel: () => Promise.resolve(),
  guessAnswer: () => Promise.resolve(),
  placeThingIntoLiving: () => undefined,
  placeThingIntoNonliving: () => undefined,
  placeOrganismInTile: () => Promise.resolve(),
  guessConnectionType: () => Promise.resolve(),
  level3PlaceOrganismInTile: () => Promise.resolve(),
  level3ConnectTiles: () => Promise.resolve(),
  level3DisconnectTiles: () => Promise.resolve(),
  level3RemoveOrganismFromTile: () => Promise.resolve(),
  level3SubmitToTeacher: () => Promise.resolve(),
});

interface ThingImage {
  id: string;
  src: string;
}

function getLivingThingImages(things: string[]): Promise<ThingImage[]> {
  return Promise.all(
    things.map(async (thing) => {
      const found = data.living.find((item) => item.id === thing);

      if (!found) {
        throw new Error('Expected to find living thing in data');
      }

      let downloadUrl;
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        downloadUrl = await storage.ref(found.src).getDownloadURL();
      } catch (error) {
        log(error);
      }

      return {
        id: thing,
        src: downloadUrl as string,
      };
    }),
  );
}

function getThingImages(things: ThingStateDTO[]): Promise<ThingImage[]> {
  return Promise.all(
    things.map(async (thing) => {
      let found;
      if (thing.living) {
        found = data.living.find((item) => item.id === thing.id);
      } else {
        found = data.nonliving.find((item) => item.id === thing.id);
      }

      if (!found) {
        throw new Error('Expected to find living/nonliving thing in data.');
      }

      let downloadUrl;
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        downloadUrl = await storage.ref(found.src).getDownloadURL();
      } catch (error) {
        log(error);
      }

      return {
        id: thing.id,
        src: downloadUrl as string,
      };
    }),
  );
}

function buildLevel1State(
  state: Level1StateDTO,
  thingImages?: ThingImage[],
): ILevel1State {
  const thingsStateMap = new Map<string, ThingStateDTO>();
  state.things.forEach((item) => {
    thingsStateMap.set(item.id, item);
  });

  const thingImagesMap = new Map<string, ThingImage>();
  if (thingImages) {
    thingImages.forEach((item) => {
      thingImagesMap.set(item.id, item);
    });
  }

  function buildThingState(id: string): IThingState {
    const thingState = thingsStateMap.get(id);

    if (!thingState) {
      throw new Error('Expected to find thing state.');
    }

    let found;
    if (thingState.living) {
      found = data.living.find((item) => item.id === id);
    } else {
      found = data.nonliving.find((item) => item.id === id);
    }

    if (!found) {
      throw new Error('Expected to find living/nonliving thing in data.');
    }

    const thingImageSrc = thingImagesMap.get(id);

    return {
      ...found,
      src: thingImageSrc ? thingImageSrc.src : '',
      living: true,
      points: thingState.points,
      attempted: thingState.attempted,
      question: {
        ...found.question,
        isEligibleForFirstTry: thingState.eligibleForFirstTry,
        points: thingState.questionPoints,
        answers: found.question.answers.map((answer) => {
          let attempted = false;
          if (thingState.attemptedAnswers) {
            attempted = thingState.attemptedAnswers.includes(answer.id);
          }

          return {
            ...answer,
            attempted,
          };
        }),
      },
    };
  }

  let activeThing: IThingState | undefined;
  const activeThingId = state.activeThing;
  if (activeThingId) {
    activeThing = buildThingState(activeThingId);
  }

  return {
    status: state.status,
    firstTries: state.firstTries,
    living: state.living.map((id) => buildThingState(id)),
    nonliving: state.nonliving.map((id) => buildThingState(id)),
    uncategorized: state.uncategorized.map((id) => buildThingState(id)),
    activeThing,
    activeQuestion:
      state.activeThingQuestionActive && activeThing
        ? activeThing.question
        : undefined,
    points: state.points,
    maxPoints: state.maxPoints,
    unlockPoints: state.unlockPoints,
  };
}

function buildLevel2State(
  state: Level2StateDTO,
  thingImages?: ThingImage[],
): ILevel2State {
  const thingImagesMap = new Map<string, ThingImage>();
  if (thingImages) {
    thingImages.forEach((item) => {
      thingImagesMap.set(item.id, item);
    });
  }

  const notPlaced: ILivingThing[] = state.notPlaced.map((item) => {
    const thing = data.living.find((livingThing) => livingThing.id === item);

    if (!thing) {
      throw new Error(`Expected to find living thing for ${item}`);
    }

    const thingImageSrc = thingImagesMap.get(item);

    return {
      ...thing,
      src: thingImageSrc ? thingImageSrc.src : '',
    };
  });

  const tiles: ITile[] = state.tiles.map((item) => {
    let placedOrganism: ILivingThing | null = null;
    if (item.placedOrganism) {
      const found = data.living.find(
        (livingThing) => livingThing.id === item.placedOrganism,
      );

      if (!found) {
        throw new Error(
          `Expected to find living thing for ${item.placedOrganism}`,
        );
      }

      const thingImageSrc = thingImagesMap.get(item.placedOrganism);

      placedOrganism = {
        ...found,
        src: thingImageSrc ? thingImageSrc.src : '',
      };
    }

    return {
      ...item,
      possibleOrganisms: item.possibleOrganisms || [],
      attemptedOrganisms: item.attemptedOrganisms || [],
      placedOrganism: item.placedOrganism ? placedOrganism : null,
    };
  });

  let connectionTypeQuestion: IConnectionState | undefined;
  if (state.connectionTypeQuestion) {
    log('Has Connection Type Question');
    const species: IConnectionSpecies[] = state.connectionTypeQuestion.ids.map(
      (id) => {
        const found = data.living.find((livingThing) => livingThing.id === id);

        if (!found) {
          throw new Error(`Expected to find living thing for ${id}`);
        }

        const thingImageSrc = thingImagesMap.get(id);

        return {
          id,
          src: thingImageSrc ? thingImageSrc.src : '',
          name: found.name,
        };
      },
    );

    connectionTypeQuestion = {
      species: [species[0], species[1]],
      type: state.connectionTypeQuestion.type,
      attemptedTypes: state.connectionTypeQuestion.attemptedTypes,
    };
  } else {
    log('Does not have connetion type question');
  }

  return {
    status: state.status,
    points: state.points,
    maxPoints: state.maxPoints,
    unlockPoints: state.unlockPoints,
    firstTries: state.firstTries,
    notPlaced,
    tiles,
    tileConnections: state.tileConnections,
    connectionTypeQuestion,
  };
}

function buildLevel3State(
  state: Level3StateDTO,
  thingImages?: ThingImage[],
): ILevel3State {
  const thingImagesMap = new Map<string, ThingImage>();
  if (thingImages) {
    thingImages.forEach((item) => {
      thingImagesMap.set(item.id, item);
    });
  }

  const notPlaced: ILivingThing[] = state.notPlaced.map((item) => {
    const thing = data.living.find((livingThing) => livingThing.id === item);

    if (!thing) {
      throw new Error(`Expected to find living thing for ${item}`);
    }

    const thingImageSrc = thingImagesMap.get(item);

    return {
      ...thing,
      src: thingImageSrc ? thingImageSrc.src : '',
    };
  });

  const tiles: ITile[] = state.tiles.map((item) => {
    let placedOrganism: ILivingThing | null = null;
    if (item.placedOrganism) {
      const found = data.living.find(
        (livingThing) => livingThing.id === item.placedOrganism,
      );
      if (!found) {
        throw new Error(
          `Expected to find living thing for ${item.placedOrganism}`,
        );
      }

      const thingImageSrc = thingImagesMap.get(item.placedOrganism);

      placedOrganism = {
        ...found,
        src: thingImageSrc ? thingImageSrc.src : '',
      };
    }

    return {
      ...item,
      placedOrganism: item.placedOrganism ? placedOrganism : null,
      possibleOrganisms: [],
      attemptedOrganisms: [],
    };
  });

  return {
    ...state,
    notPlaced,
    tiles,
  };
}

interface Props {
  children: ReactNode;
}

const LevelStateProvider: FunctionComponent<Props> = ({ children }) => {
  const [currentLevel, setCurrentLevel] = useState<1 | 2 | 3 | undefined>(1);
  const [thingImages, setThingImages] = useState<ThingImage[] | undefined>();
  const [currentLevelInitialized, setCurrentLevelInitialzed] = useState<
    boolean | undefined
  >();
  const [level1State, setLevel1State] = useImmer<ILevel1State | undefined>(
    undefined,
  );
  const [level2State, setLevel2State] = useImmer<ILevel2State | undefined>(
    undefined,
  );
  const [level3State, setLevel3State] = useImmer<ILevel3State | undefined>(
    undefined,
  );

  const destroy = useCallback(() => {
    setLevel1State(() => undefined);
    setLevel2State(() => undefined);
    setLevel3State(() => undefined);
    setCurrentLevel(undefined);
  }, [setLevel1State, setLevel2State, setLevel3State]);

  const handleSetCurrentLevel = useCallback((level: 1 | 2 | 3) => {
    setCurrentLevel(level);
    setCurrentLevelInitialzed(false);
  }, []);

  const initializeLevel = useCallback(async () => {
    if (currentLevelInitialized) {
      return;
    }

    async function getLevel1InitialState(): Promise<void> {
      const getLevel1StateCallable = functions.httpsCallable('getLevel1State');
      const result = await getLevel1StateCallable();
      const state = plainToClass(Level1StateDTO, result.data);

      // fetch images for these things
      const items = await getThingImages(state.things);
      setThingImages(items);

      setLevel1State(() => buildLevel1State(state, items));
    }

    async function getLevel2InitialState(): Promise<void> {
      const getLevel2StateCallable = functions.httpsCallable('getLevel2State');
      const result = await getLevel2StateCallable();
      const state = plainToClass(Level2StateDTO, result.data);

      const ids: string[] = [
        ...state.notPlaced,
        ...state.tiles
          .filter((item) => item.placedOrganism)
          .map((item) => item.placedOrganism as string),
      ];

      const items = await getLivingThingImages(ids);
      setThingImages(items);

      setLevel2State(() => buildLevel2State(state, items));
    }

    async function getLevel3InitialState(): Promise<void> {
      const getLevel3StateCallable = functions.httpsCallable('getLevel3State');
      const result = await getLevel3StateCallable();
      const state = plainToClass(Level3StateDTO, result.data);

      const ids: string[] = [
        ...state.notPlaced,
        ...state.tiles
          .filter((item) => item.placedOrganism)
          .map((item) => item.placedOrganism as string),
      ];

      const items = await getLivingThingImages(ids);
      setThingImages(items);

      setLevel3State(() => buildLevel3State(state, items));
    }

    switch (currentLevel) {
      case 1:
        await getLevel1InitialState();
        break;
      case 2:
        await getLevel2InitialState();
        break;
      case 3:
        await getLevel3InitialState();
        break;
      default:
        break;
    }

    setCurrentLevelInitialzed(true);
  }, [
    setLevel1State,
    setLevel2State,
    setLevel3State,
    currentLevel,
    currentLevelInitialized,
  ]);

  const restartLevel = useCallback(
    async (level?: 1 | 2 | 3) => {
      async function getLevel1InitialState(): Promise<void> {
        const getLevel1StateCallable = functions.httpsCallable('restartLevel1');
        const result = await getLevel1StateCallable();
        const state = plainToClass(Level1StateDTO, result.data);

        // fetch images for these things
        const items = await getThingImages(state.things);
        setThingImages(items);

        setLevel1State(() => buildLevel1State(state, items));
      }

      async function getLevel2InitialState(): Promise<void> {
        const getLevel2StateCallable = functions.httpsCallable('restartLevel2');
        const result = await getLevel2StateCallable();
        const state = plainToClass(Level2StateDTO, result.data);

        const ids: string[] = [
          ...state.notPlaced,
          ...state.tiles
            .filter((item) => item.placedOrganism)
            .map((item) => item.placedOrganism as string),
        ];

        const items = await getLivingThingImages(ids);
        setThingImages(items);

        setLevel2State(() => buildLevel2State(state, items));
      }

      async function getLevel3InitialState(): Promise<void> {
        const getLevel3StateCallable = functions.httpsCallable('restartLevel3');
        const result = await getLevel3StateCallable();
        const state = plainToClass(Level3StateDTO, result.data);

        const ids: string[] = [
          ...state.notPlaced,
          ...state.tiles
            .filter((item) => item.placedOrganism)
            .map((item) => item.placedOrganism as string),
        ];

        const items = await getLivingThingImages(ids);
        setThingImages(items);

        setLevel3State(() => buildLevel3State(state, items));
      }

      const switchLevel = level || currentLevel;
      switch (switchLevel) {
        case 1:
          await getLevel1InitialState();
          break;
        case 2:
          await getLevel2InitialState();
          break;
        case 3:
          await getLevel3InitialState();
          break;
        default:
          break;
      }

      setCurrentLevelInitialzed(true);
    },
    [setLevel1State, setLevel2State, setLevel3State, currentLevel],
  );

  const placeThingIntoLiving = useCallback(
    async (index?: number) => {
      // Optimistic Response
      setLevel1State((draft) => {
        if (!draft) {
          return;
        }

        if (!draft.activeThing) {
          return;
        }

        // must optimistic response the status otherwise intro dialog will reappear
        draft.status = LevelStatus.INPROGRESS;

        draft.activeThing.points = null;

        if (index || index === 0) {
          draft.living.splice(index, 0, draft.activeThing);
        } else {
          draft.living.push(draft.activeThing);
        }

        draft.activeThing = undefined;
      });

      const level1EventCallable = functions.httpsCallable('level1Event');
      const event = new PlaceIntoLivingEventDTO({
        index: index || index === 0 ? index : null,
      });

      const result = await level1EventCallable(event);
      const state = plainToClass(Level1StateDTO, result.data);
      setLevel1State(() => buildLevel1State(state, thingImages));
    },
    [setLevel1State, thingImages],
  );

  const placeThingIntoNonliving = useCallback(
    async (index?: number) => {
      // Optimistic Response
      setLevel1State((draft) => {
        if (!draft) {
          return;
        }

        if (!draft.activeThing) {
          return;
        }

        // must optimistic response the status otherwise intro dialog will reappear
        draft.status = LevelStatus.INPROGRESS;

        draft.activeThing.points = null;

        if (index || index === 0) {
          draft.nonliving.splice(index, 0, draft.activeThing);
        } else {
          draft.nonliving.push(draft.activeThing);
        }

        draft.activeThing = undefined;
      });

      const level1EventCallable = functions.httpsCallable('level1Event');
      const event = new PlaceIntoNonlivingEventDTO({
        index: index || index === 0 ? index : null,
      });
      const result = await level1EventCallable(event);
      const state = plainToClass(Level1StateDTO, result.data);
      setLevel1State(() => buildLevel1State(state, thingImages));
    },
    [setLevel1State, thingImages],
  );

  const guessAnswer = useCallback(
    async (guess: IQuestionAnswer) => {
      const level1EventCallable = functions.httpsCallable('level1Event');
      const event = new GuessAnswerEventDTO({ id: guess.id });
      const result = await level1EventCallable(event);
      const state = plainToClass(Level1StateDTO, result.data);
      setLevel1State(() => buildLevel1State(state, thingImages));
    },
    [setLevel1State, thingImages],
  );

  const placeOrganismInTile = useCallback(
    async (organismId: string, tileId: number) => {
      setLevel2State((draft) => {
        if (!draft) {
          return;
        }

        draft.status = LevelStatus.INPROGRESS;
      });

      const level2EventCallable = functions.httpsCallable('level2Event');
      const event = new PlaceOrganismInTileEventDTO({ organismId, tileId });
      const result = await level2EventCallable(event);
      const state = plainToClass(Level2StateDTO, result.data);
      setLevel2State(() => buildLevel2State(state, thingImages));
    },
    [setLevel2State, thingImages],
  );

  const guessConnectionType = useCallback(
    async (type: ConnectionType) => {
      const level2EventCallable = functions.httpsCallable('level2Event');
      const event = new GuessConnectionTypeEventDTO({ type });
      const result = await level2EventCallable(event);
      const state = plainToClass(Level2StateDTO, result.data);
      setLevel2State(() => buildLevel2State(state, thingImages));
    },
    [setLevel2State, thingImages],
  );

  const level3PlaceOrganismInTile = useCallback(
    async (organismId: string, tileId: number) => {
      setLevel3State((draft) => {
        if (!draft) {
          return;
        }

        draft.status = LevelStatus.INPROGRESS;
      });

      const level3EventCallable = functions.httpsCallable('level3Event');
      const event = new PlaceOrganismInTileEventDTO({ organismId, tileId });
      const result = await level3EventCallable(event);
      const state = plainToClass(Level3StateDTO, result.data);
      setLevel3State(() => buildLevel3State(state, thingImages));
    },
    [setLevel3State, thingImages],
  );

  const level3RemoveOrganismFromTile = useCallback(
    async (tileId: number) => {
      const level3EventCallable = functions.httpsCallable('level3Event');
      const event = new RemoveOrganismFromTileEventDTO({ tileId });
      const result = await level3EventCallable(event);
      const state = plainToClass(Level3StateDTO, result.data);
      setLevel3State(() => buildLevel3State(state, thingImages));
    },
    [setLevel3State, thingImages],
  );

  const level3ConnectTiles = useCallback(
    async (tileId0: number, tileId1: number) => {
      const level3EventCallable = functions.httpsCallable('level3Event');
      const event = new ConnectTilesEventDTO({ tileId0, tileId1 });
      const result = await level3EventCallable(event);
      const state = plainToClass(Level3StateDTO, result.data);
      setLevel3State(() => buildLevel3State(state, thingImages));
    },
    [setLevel3State, thingImages],
  );

  const level3DisconnectTiles = useCallback(
    async (tileId0: number, tileId1: number) => {
      const level3EventCallable = functions.httpsCallable('level3Event');
      const event = new DisconnectTilesEventDTO({ tileId0, tileId1 });
      const result = await level3EventCallable(event);
      const state = plainToClass(Level3StateDTO, result.data);
      setLevel3State(() => buildLevel3State(state, thingImages));
    },
    [setLevel3State, thingImages],
  );

  const level3SubmitToTeacher = useCallback(async () => {
    setLevel3State((draft) => {
      if (!draft) {
        log('expected draft to exist');
        return;
      }

      if (draft.status !== LevelStatus.INPROGRESS) {
        log('expected in progress game');
        return;
      }

      draft.status = LevelStatus.LOCKED;
    });

    const level3EventCallable = functions.httpsCallable('level3Event');
    const event = new SubmitToTeacherEventDTO();
    const result = await level3EventCallable(event);
    const state = plainToClass(Level3StateDTO, result.data);
    setLevel3State(() => buildLevel3State(state, thingImages));
  }, [setLevel3State, thingImages]);

  return (
    <LevelStateContext.Provider
      value={{
        currentLevel,
        currentLevelInitialized,
        level1State,
        level2State,
        level3State,
        setCurrentLevel: handleSetCurrentLevel,
        destroy,
        initializeLevel,
        restartLevel,
        guessAnswer,
        placeThingIntoNonliving,
        placeThingIntoLiving,
        placeOrganismInTile,
        guessConnectionType,
        level3PlaceOrganismInTile,
        level3RemoveOrganismFromTile,
        level3ConnectTiles,
        level3DisconnectTiles,
        level3SubmitToTeacher,
      }}
    >
      {children}
    </LevelStateContext.Provider>
  );
};

export default LevelStateProvider;
