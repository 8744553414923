import { FunctionComponent, forwardRef, MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import {
  Button,
  Typography,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';

import ILivingThing from 'types/interfaces/ILivingThing';

import GradientContainer from 'components/GradientContainer';

const useStyles = makeStyles(() => ({
  root: {
    width: '330px',
    height: '600px',
    padding: '1rem',
    boxSizing: 'border-box',
    color: 'white',
    '@media (min-width: 1920px)': {
      height: '800px',
    },
  },
  scroll: {
    padding: '1rem',
    height: '100%',
    overflowX: 'hidden',
    display: 'inline-flex',
    flexDirection: 'column',
  },
  title: {
    marginBottom: '1.25rem',
    fontSize: '1.25rem',
    '@media (min-width: 1920px)': {
      fontSize: '2.125rem',
    },
  },
  img: {
    width: '100%',
    objectFit: 'cover',
    objectPosition: '50% 50%',
    height: '140px',
    borderRadius: '0.5rem',
  },
  factsContainer: {
    marginTop: '0.5rem',
  },
  fact: {
    marginTop: '0.5rem',
  },
  hintContainer: {
    marginTop: '0.5rem',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '1rem',
  },
  button: {
    marginLeft: '1rem',
  },
  text: {
    fontSize: '0.875rem',
    '@media (min-width: 1920px)': {
      fontSize: '1rem',
    },
  },
  textHeader: {
    fontSize: '1rem',
    '@media (min-width: 1920px)': {
      fontSize: '1.25rem',
    },
  },
}));

export interface Props {
  organism: ILivingThing | null;
  loading?: boolean;
  onClose: () => void;
  onRemove?: () => void;
  innerRef?:
    | ((instance: HTMLDivElement) => void)
    | MutableRefObject<HTMLDivElement | null>
    | null;
}

const OrganismDrawer: FunctionComponent<Props> = ({
  organism,
  loading,
  onClose,
  onRemove,
  innerRef,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['level2', 'common']);

  return (
    <GradientContainer className={classes.root} innerRef={innerRef}>
      <div className={classes.scroll}>
        <Typography
          className={classes.title}
          variant="h4"
          component="h4"
          align="center"
        >
          {organism ? organism.name : ''}
        </Typography>
        {organism && (
          <img className={classes.img} alt={organism.name} src={organism.src} />
        )}
        <div className={classes.factsContainer}>
          <Typography
            variant="h6"
            component="h6"
            className={classes.textHeader}
          >
            {t('Facts')}
          </Typography>
          {organism &&
            organism.facts.map((fact, index) => (
              <Typography
                className={`${clsx(index > 0 && classes.fact)} ${classes.text}`}
                key={fact}
              >
                {fact}
              </Typography>
            ))}
        </div>
        <div className={classes.hintContainer}>
          <Typography
            variant="h6"
            component="h6"
            className={classes.textHeader}
          >
            {t('Hint')}
          </Typography>
          <Typography className={classes.text}>
            {organism ? organism.tileHint : ''}{' '}
          </Typography>
        </div>
        <div className={classes.actionsContainer}>
          <Button variant="contained" onClick={onClose} disabled={loading}>
            {t('back')}
          </Button>
          {onRemove && (
            <Button
              disabled={loading}
              className={classes.button}
              variant="contained"
              onClick={onRemove}
            >
              {t('common:remove')}
            </Button>
          )}
          {loading && <CircularProgress className={classes.button} size={24} />}
        </div>
      </div>
    </GradientContainer>
  );
};

// eslint-disable-next-line react/display-name
export default forwardRef<HTMLDivElement, Props>((props, ref) => (
  <OrganismDrawer {...props} innerRef={ref} />
));
