import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import {
  makeStyles,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  actions: {
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export interface Props {
  open: boolean;
  onClose: () => void;
}

const ForgotPasswordDialog: FunctionComponent<Props> = ({ open, onClose }) => {
  const { t } = useTranslation(['studentLogin', 'common']);
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('ForgotAccessCodeTitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('ForgotAccessCodeContent')}</DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={onClose} variant="contained">
          {t('common:okay')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ForgotPasswordDialog;
