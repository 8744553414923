import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Kovach from 'assets/kovach.jpg';

const useStyles = makeStyles(() => ({
  kovachLevel1: {
    width: '103px',
    height: '64px',
    position: 'absolute',
    right: '500px',
  },
  kovachDefault: {
    width: '188px',
    height: '100px',
    position: 'absolute',
    bottom: 0,
    left: '15px',
  },
}));

export interface Props {
  level: number;
}

const KovachTrailSign: FunctionComponent<Props> = ({ level }: Props) => {
  const classes = useStyles();

  const className = level === 1 ? classes.kovachLevel1 : classes.kovachDefault;

  return <img src={Kovach} alt="" className={className} />;
};

export default KovachTrailSign;
