import React, { FunctionComponent } from 'react';

import useSound from 'use-sound';

import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@material-ui/core';
import { SupportIcon } from 'utils/iconMappings';

export interface Props {
  className?: string | undefined;
  onClick: () => void;
}

const SupportIconButton: FunctionComponent<Props> = ({
  className,
  onClick,
}: Props) => {
  const { t } = useTranslation('common');
  const [play] = useSound(`${process.env.PUBLIC_URL}/sounds/click.mp3`, {
    interrupt: true,
  });

  function handleClick(): void {
    play();
    onClick();
  }

  return (
    <Tooltip title={`${t('Support')}`}>
      <IconButton className={className} onClick={handleClick} color="primary">
        <SupportIcon />
      </IconButton>
    </Tooltip>
  );
};

export default SupportIconButton;
