import React, { FunctionComponent, useEffect, useState } from 'react';

import { motion, useAnimation } from 'framer-motion';
import { Typography, Fade, makeStyles } from '@material-ui/core';
import { log } from 'utils/log';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    width: '',
    // Mui Dialogs are at 1300
    zIndex: 1200,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: '50%',
    top: '20%',
    // transform: 'translate(-50%, -50%)',
    color: theme.palette.primary.main,
    background: 'white',
    padding: '1rem',
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.25)',
    borderRadius: '16px',
  },
}));

export interface Props {
  message: string;
  open: boolean;
  onClose: () => void;
}

const FeedbackMessage: FunctionComponent<Props> = ({
  message,
  open,
  onClose,
}) => {
  const classes = useStyles();
  const [triggerAnimation, setTriggerAnimation] = useState(false);
  const controls = useAnimation();

  useEffect(() => {
    if (!open || !onClose) {
      return undefined;
    }

    const timeout = setTimeout(() => {
      onClose();
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, [open, onClose]);

  useEffect(() => {
    if (!open) {
      return;
    }

    setTriggerAnimation(true);
  }, [open]);

  useEffect(() => {
    if (!triggerAnimation) {
      return;
    }

    setTriggerAnimation(false);
    controls
      .start({
        scale: [1, 1.1, 1],
        transition: {
          repeatType: 'reverse',
          repeat: 2,
          duration: 1,
        },
      })
      .catch((error) => {
        log(error);
      });
  }, [triggerAnimation, controls]);

  return (
    <Fade in={open}>
      <motion.div
        className={classes.root}
        initial={{ x: '-50%', y: '-20%' }}
        animate={controls}
      >
        <Typography align="center" variant="h3">
          {message}
        </Typography>
      </motion.div>
    </Fade>
  );
};

export default FeedbackMessage;
