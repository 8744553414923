import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Logo from 'assets/logoWhite.jpg';

const useStyles = makeStyles(() => ({
  img: {
    width: '303px',
    height: '70px',
    position: 'absolute',
    right: 0,
    bottom: 0,
    padding: '5px',
  },
}));

const SonoranLogo: FunctionComponent = () => {
  const classes = useStyles();

  return <img src={Logo} alt="" className={classes.img} />;
};

export default SonoranLogo;
