import HelpIcon from '@material-ui/icons/Help';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import MuiHomeIcon from '@material-ui/icons/Home';
import ReplayIcon from '@material-ui/icons/Replay';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import AssessmentIcon from '@material-ui/icons/Assessment';
import MuiStoreIcon from '@material-ui/icons/Store';
import SendIcon from '@material-ui/icons/Send';
import MuiCheckIcon from '@material-ui/icons/Check';
import MuiCloseIcon from '@material-ui/icons/Close';

export const SupportIcon = HelpIcon;
export const AchievementIcon = EmojiEventsIcon;
export const NextStepIcon = ChevronRight;
export const BackStepIcon = ChevronLeft;
export const HomeIcon = MuiHomeIcon;
export const RestartIcon = ReplayIcon;
export const PlayIcon = PlayArrowIcon;
export const SummaryIcon = AssessmentIcon;
export const StoreIcon = MuiStoreIcon;
export const SubmitIcon = SendIcon;
export const CheckIcon = MuiCheckIcon;
export const CloseIcon = MuiCloseIcon;
