import React, { FunctionComponent, ReactNode } from 'react';

import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import {
  makeStyles,
  Card,
  CardContent,
  CardActions,
  Button,
  Fade,
  Typography,
} from '@material-ui/core';

import StandalonePointBadge from 'components/StandalonePointBadge';

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 1201,
    position: 'absolute',
  },
  tutorialRoot: {
    zIndex: 1201,
    position: 'fixed',
  },
  backdrop: {
    zIndex: 1201,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    maxWidth: '600px',
    marginTop: '200px',
  },
  fakeContainer: {
    height: '64px',
  },
  pointBadge: {
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  pointBadgeText: {
    marginLeft: '1rem',
  },
}));

const variants = {
  tutorial: {
    top: '46%',
    right: '50%',
    y: '-46%',
    x: '50%',
  },
  normal: {
    top: '0%',
    right: '0%',
  },
};

export interface Props {
  active: boolean;
  children: ReactNode;
  onDismiss: () => void;
}

const LevelToolbarTutorial: FunctionComponent<Props> = ({
  active,
  children,
  onDismiss,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['levelToolbar']);

  return (
    <>
      <div className={classes.fakeContainer} />
      <Fade in={active}>
        <div className={classes.backdrop}>
          <Card className={classes.card}>
            <CardContent>
              <Typography>{t('tutorial')}</Typography>
              <div className={classes.pointBadge}>
                <StandalonePointBadge points={100} />
                <Typography className={classes.pointBadgeText}>
                  {t('tutorialBadge')}
                </Typography>
              </div>
            </CardContent>
            <CardActions>
              <Button variant="contained" onClick={onDismiss}>
                {t('okay')}
              </Button>
            </CardActions>
          </Card>
        </div>
      </Fade>
      <motion.div
        className={active ? classes.tutorialRoot : classes.root}
        variants={variants}
        initial={variants.normal}
        animate={active ? 'tutorial' : 'normal'}
        transition={{
          duration: 1,
          type: 'spring',
          damping: 11,
          stiffness: 100,
          mass: 1,
        }}
      >
        {children}
      </motion.div>
    </>
  );
};

export default LevelToolbarTutorial;
