import {
  AchievementName,
  IAchievement,
  AchievementRewardType,
} from '@sonoran-ecosystems/types';

const achievements: IAchievement[] = [
  {
    id: AchievementName.PERFECT_LEVEL_1,
    title: 'Perfect Level 1',
    subtitle: 'Get a first try every time on level 1.',
    steps: 1,
    rewardType: AchievementRewardType.PRICKLY_PEAR,
    reward: 5,
  },
  {
    id: AchievementName.PERFECT_LEVEL_2,
    title: 'Perfect Level 2',
    subtitle: 'Get a first try every time on level 2.',
    steps: 1,
    rewardType: AchievementRewardType.PRICKLY_PEAR,
    reward: 10,
  },
  {
    id: AchievementName.PERFECT_GAME,
    title: 'Perfect Game',
    subtitle: 'Get Perfect Level 1 and Perfect Level 2.',
    steps: 2,
    rewardType: AchievementRewardType.PRICKLY_PEAR,
    reward: 10,
  },
  {
    id: AchievementName.FINISH_ALL_LEVELS,
    title: 'Finish All Levels',
    subtitle: 'Finish level 1, 2, and 3!',
    steps: 3,
    rewardType: AchievementRewardType.PRICKLY_PEAR,
    reward: 10,
  },
  {
    id: AchievementName.FIVE_FIRST_TRIES,
    title: '5 First Tries in a Row',
    subtitle: 'Get 5 First Tries in a row in either Level 1 or 2.',
    steps: 1,
    rewardType: AchievementRewardType.PRICKLY_PEAR,
    reward: 5,
  },
];

export default achievements;
