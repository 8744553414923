import React, { FunctionComponent, SyntheticEvent, useCallback } from 'react';

import clsx from 'clsx';
import { motion } from 'framer-motion';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography, Button } from '@material-ui/core';
import { DraggableProvided } from 'react-beautiful-dnd';

import PointBadge from '../PointBadge';

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-flex',
    flexDirection: 'column',
    padding: '0.5rem',
    paddingBottom: '0.75rem',
    boxSizing: 'border-box',
  },
  rootLarge: {
    width: '9.25rem',
    minHeight: '6.25rem',
  },
  rootSmall: {
    width: '7rem',
    minHeight: '7rem',
  },
  img: {
    objectFit: 'cover',
    borderRadius: '12px',
  },
  imgLarge: {
    height: '5.5rem',
  },
  imgSmall: {
    height: '3.5rem',
  },
  name: {
    marginTop: '0.75rem',
  },
  button: {
    marginTop: '0.5rem',
  },
}));

export interface Props {
  className?: string;
  small?: boolean;
  name: string;
  src: string;
  points: number | null;
  draggableProvided?: DraggableProvided | undefined;
  disableMotion?: boolean;
  onClick?: () => void;
  // Including both of these will show a button below the text
  buttonText?: string;
  onButtonClick?: () => void;
}

const ThingCard: FunctionComponent<Props> = ({
  className,
  small,
  name,
  src,
  points,
  draggableProvided,
  disableMotion,
  onClick,
  buttonText,
  onButtonClick,
}: Props) => {
  const classes = useStyles();

  const handleButtonClick = useCallback(
    (event: SyntheticEvent) => {
      if (onButtonClick) {
        event.stopPropagation();
        onButtonClick();
      }
    },
    [onButtonClick],
  );

  return (
    <PointBadge
      className={className}
      points={points}
      draggableProvided={draggableProvided}
    >
      <motion.div
        onClick={onClick}
        whileHover={
          disableMotion
            ? undefined
            : {
                scale: 1.05,
                rotate: [0, 5, -5, 0],
                transition: {
                  duration: 0.25,
                  ease: 'easeInOut',
                },
              }
        }
      >
        <Paper
          className={clsx(
            classes.root,
            small ? classes.rootSmall : classes.rootLarge,
          )}
        >
          <img
            className={clsx(
              classes.img,
              small ? classes.imgSmall : classes.imgLarge,
            )}
            alt={name}
            src={src}
          />
          <Typography
            className={classes.name}
            variant="subtitle2"
            align="center"
          >
            {name}
          </Typography>
          {buttonText && onButtonClick && (
            <Button
              className={classes.button}
              variant="contained"
              onClick={handleButtonClick}
            >
              {buttonText}
            </Button>
          )}
        </Paper>
      </motion.div>
    </PointBadge>
  );
};

export default ThingCard;
