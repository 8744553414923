import React, { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import {
  makeStyles,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  actions: {
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export interface Props {
  open: boolean;
  onClose: () => void;
  title: ReactNode;
  content: ReactNode;
}

const IntroductionDialog: FunctionComponent<Props> = ({
  open,
  onClose,
  title,
  content,
}) => {
  const { t } = useTranslation(['common']);
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disablePortal
      style={{ position: 'absolute' }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={onClose} variant="contained">
          {t('okay')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IntroductionDialog;
