import {
  MutableRefObject,
  FunctionComponent,
  ReactNode,
  forwardRef,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, Typography, makeStyles } from '@material-ui/core';

import ScrollView from 'components/ScrollView';

const useStyles = makeStyles(() => ({
  root: {
    width: '300px',
    height: '600px',
    '@media (min-width: 1920px)': {
      height: '800px',
    },
  },
  headerRoot: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  divider: {
    marginTop: '0.5rem',
    width: '40px',
  },
  dividerRoot: {
    backgroundColor: '#C4C4C4',
  },
}));

export interface Props {
  children: ReactNode;
  innerRef?:
    | ((instance: HTMLDivElement) => void)
    | MutableRefObject<HTMLDivElement | null>
    | null;
}

const OrganismBank: FunctionComponent<Props> = ({
  children,
  innerRef,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation(['level2']);

  return (
    <ScrollView
      className={classes.root}
      innerRef={innerRef}
      header={
        <div className={classes.headerRoot}>
          <Typography align="center" variant="h4" component="h4">
            {t('Organisms')}
          </Typography>
          <div className={classes.divider}>
            <Divider
              classes={{
                root: classes.dividerRoot,
              }}
            />
          </div>
        </div>
      }
    >
      {children}
    </ScrollView>
  );
};

// eslint-disable-next-line react/display-name
export default forwardRef<HTMLDivElement, Props>((props, ref) => (
  <OrganismBank {...props} innerRef={ref} />
));
