import { createMuiTheme } from '@material-ui/core/styles';

const primaryLinearGradient =
  'linear-gradient(180deg, #4A6D80 0%, #1D4961 100%)';

export default createMuiTheme({
  palette: {
    primary: {
      light: '#4a6d80',
      main: '#1D4961',
      dark: '#143343',
    },
    secondary: {
      light: '#33bec5',
      main: '#00aeb7',
      dark: '#007980',
    },
  },
  typography: {
    fontFamily: ['Nunito'].join(','),
    h1: {
      fontWeight: 'bold',
    },
    h2: {
      fontWeight: 400,
    },
    h3: {
      fontWeight: 'bold',
    },
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 400,
    },
    h6: {
      fontWeight: 'bold',
    },
    subtitle1: {
      fontWeight: 400,
    },
    subtitle2: {
      fontWeight: 'bold',
    },
    body1: {
      fontWeight: 400,
    },
    body2: {
      fontWeight: 'lighter',
    },
    button: {
      fontWeight: 'bold',
    },
    caption: {
      fontWeight: 400,
    },
    overline: {
      fontWeight: 400,
    },
  },
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: 16,
      },
      elevation1: {
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.25)',
      },
    },
    MuiButton: {
      contained: {
        borderRadius: '16px',
        background: primaryLinearGradient,
        color: 'white',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
        '&:hover': {
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
          background: 'linear-gradient(180deg, #4A6D80 0%, #4A6D80 100%)',
        },
        '&.Mui-disabled': {
          background: 'rgba(0, 0, 0, 0.12)',
          boxShadow: 'none',
        },
      },
    },
    MuiIconButton: {
      colorPrimary: {
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
        background: primaryLinearGradient,
        color: 'white',
      },
      root: {
        '&.Mui-disabled': {
          background: 'rgba(0, 0, 0, 0.12)',
          boxShadow: 'none',
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#1D4961',
          display: 'flex',
          alignItems: 'center',
          height: '100vh',
          minHeight: '768px',
          '@media (min-width: 1366px)': {
            justifyContent: 'center',
          },
          '@media (min-width: 1920px)': {
            minHeight: '1080px',
          },
        },
        '#root': {
          minWidth: '1366px',
          height: '768px',
          outline: 'black 5px solid',
          '@media (min-width: 1920px)': {
            minWidth: '1920px',
            height: '1080px',
          },
        },
      },
    },
    /*
    MuiAvatar: {
      root: {
        boxShadow:
          'inset 0px 20px 20px rgba(255, 255, 255, 0.25), inset 0px -40px 20px rgba(0, 0, 0, 0.15), inset 0px 5px 15px rgba(255, 255, 255, 0.7), inset 0px -30px 60px rgba(0, 0, 0, 0.5), inset 0px 20px 40px rgba(255, 255, 255, 0.5)',
        borderRadius: '4px',
      },
    },
    */
  },
});
