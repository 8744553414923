import { FunctionComponent, useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { auth, database, storage } from 'firebaseApp';

import { plainToClass } from 'class-transformer';

import {
  AvatarStatus,
  IAvatar,
  Student,
  StudentLevelLockedStatus,
} from '@sonoran-ecosystems/types';

import useLevelState from 'hooks/useLevelState';

import AchievementsDialog from 'components/Achievements/DialogWithListener';
import ShopDialog from 'components/Shop/DialogWithListener';
import MenuBackground from 'components/MenuBackground';
import SelectLevelCard from 'components/SelectLevelCard';
import StoreIconButton from 'components/IconButtons/Store';
import AchievementIconButton from 'components/IconButtons/Achievement';
import UserCard from 'components/UserCard';

import staticAvatarData from 'data/avatar';

import { log } from 'utils/log';

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    marginTop: '2rem',
    padding: '1rem',
  },
  row: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '400px',
  },
  levelContainer: {
    marginTop: '1.25rem',
    display: 'flex',
    flexDirection: 'row',
  },
  levelCard: {
    margin: '2rem',
  },
  buttonMarginLeft: {
    marginLeft: '1.5rem',
  },
}));

const SelectLevel: FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation(['levelSelect', 'common']);
  const { restartLevel, setCurrentLevel, currentLevel } = useLevelState();
  const [initializing, setInitializing] = useState(false);
  const [openShopDialog, setOpenShopDialog] = useState(false);
  const [openAchievementsDialog, setOpenAchievementsDialog] = useState(false);
  const [student, setStudent] = useState<Student | undefined>();
  const [avatar, setAvatar] = useState<IAvatar | undefined>();
  const [avatarSrc, setAvatarSrc] = useState<string | undefined>();
  const [
    studentLevelLockedStatus,
    setStudentLevelLockedStatus,
  ] = useState<StudentLevelLockedStatus>();

  useEffect(() => {
    const uid = auth.currentUser?.uid;
    if (!uid) {
      return undefined;
    }

    const unsubscribe = database
      .collection('studentLevelLockedStatuses')
      .doc(uid)
      .onSnapshot((snapshot) => {
        if (!snapshot.exists) {
          return;
        }

        setStudentLevelLockedStatus(
          plainToClass(StudentLevelLockedStatus, snapshot.data()),
        );
      });

    return unsubscribe;
  }, []);

  useEffect(() => {
    const uid = auth.currentUser?.uid;
    if (!uid) {
      return;
    }

    async function execute(): Promise<void> {
      const documentSnapshot = await database
        .collection('students')
        .doc(uid)
        .get();

      const studentData = documentSnapshot.data();

      if (!studentData) {
        return;
      }

      const studentInst = plainToClass(Student, studentData);

      if (studentInst.classroom) {
        const classroomDocumentSnapshot = await database
          .collection('classrooms')
          .doc(studentInst.classroom)
          .get();

        const classroomData = classroomDocumentSnapshot.data();

        if (classroomData && classroomData.name) {
          studentInst.classroom = classroomData.name as string;
        }
      }

      setStudent(studentInst);
    }

    execute().catch(log);
  }, []);

  useEffect(() => {
    const uid = auth.currentUser?.uid;
    if (!uid) {
      return undefined;
    }

    const unsubscribe = database
      .collection('students')
      .doc(uid)
      .collection('avatars')
      .where('status', '==', AvatarStatus.ACTIVE)
      .onSnapshot((snapshot) => {
        if (snapshot.empty) {
          setAvatar(undefined);
          return;
        }

        const avatarId = snapshot.docs[0].id;

        if (!avatarId) {
          setAvatar(undefined);
          return;
        }

        setAvatar(staticAvatarData.find((item) => item.id === avatarId));
      });

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (!avatar) {
      return;
    }

    async function execute(): Promise<void> {
      if (!avatar) {
        return;
      }

      let downloadUrl;
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        downloadUrl = await storage.ref(avatar.src).getDownloadURL();
      } catch (error) {
        log(error);
      }

      setAvatarSrc(downloadUrl);
    }

    execute().catch(log);
  }, [avatar]);

  const handleLogout = useCallback(async () => {
    await auth.signOut();
    history.push('login/student');
  }, [history]);

  const handlePlayLevel1 = useCallback(() => {
    setCurrentLevel(1);
    history.push('levels/1');
  }, [history, setCurrentLevel]);

  const handleRestartLevel1 = useCallback(async () => {
    setCurrentLevel(1);
    setInitializing(true);
    await restartLevel(1);
    setInitializing(false);
    history.push('levels/1');
  }, [history, restartLevel, setCurrentLevel]);

  const handlePlayLevel2 = useCallback(() => {
    setCurrentLevel(2);
    history.push('levels/2');
  }, [history, setCurrentLevel]);

  const handleRestartLevel2 = useCallback(async () => {
    setCurrentLevel(2);
    setInitializing(true);
    await restartLevel(2);
    setInitializing(false);
    history.push('levels/2');
  }, [history, setCurrentLevel, restartLevel]);

  const handlePlayLevel3 = useCallback(() => {
    setCurrentLevel(3);
    history.push('levels/3');
  }, [history, setCurrentLevel]);

  /*
  const handleRestartLevel3 = useCallback(async () => {
    setCurrentLevel(3);
    setInitializing(true);
    await restartLevel(3);
    setInitializing(false);
    history.push('levels/3');
  }, [history, setCurrentLevel, restartLevel]);

   */

  return (
    <>
      <MenuBackground
        headerRightChildren={
          <>
            <StoreIconButton onClick={() => setOpenShopDialog(true)} />
            <AchievementIconButton
              className={classes.buttonMarginLeft}
              onClick={() => setOpenAchievementsDialog(true)}
            />
            <UserCard
              className={classes.buttonMarginLeft}
              src={avatarSrc}
              title={student ? student.name : ''}
              subtitle={student ? student.classroom : ''}
              onLogout={handleLogout}
            />
          </>
        }
      >
        <div className={classes.root}>
          <Typography variant="h4" component="h1">
            {t('SelectALevel')}
          </Typography>
          <div className={classes.levelContainer}>
            <SelectLevelCard
              className={classes.levelCard}
              name="1"
              disabled={initializing}
              loading={currentLevel === 1 && initializing}
              onPlay={handlePlayLevel1}
              onRestart={handleRestartLevel1}
            />
            <SelectLevelCard
              className={classes.levelCard}
              name="2"
              locked={
                studentLevelLockedStatus
                  ? studentLevelLockedStatus.level2
                  : true
              }
              disabled={initializing}
              loading={currentLevel === 2 && initializing}
              onPlay={handlePlayLevel2}
              onRestart={handleRestartLevel2}
            />
            <SelectLevelCard
              className={classes.levelCard}
              name="3"
              locked={
                studentLevelLockedStatus
                  ? studentLevelLockedStatus?.level3
                  : true
              }
              disabled={initializing}
              loading={currentLevel === 3 && initializing}
              onPlay={handlePlayLevel3}
            />
          </div>
        </div>
        <AchievementsDialog
          open={openAchievementsDialog}
          onClose={() => setOpenAchievementsDialog(false)}
        />
        <ShopDialog
          open={openShopDialog}
          onClose={() => setOpenShopDialog(false)}
        />
      </MenuBackground>
    </>
  );
};

export default SelectLevel;
