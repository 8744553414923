import { MutableRefObject, FunctionComponent, ReactNode } from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-block',
    borderRadius: '1rem',
    background:
      'linear-gradient(180deg, #1D4961 0%, #1D4961 19.79%, rgba(0, 0, 0, 0.1) 100%)',
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.35)',
  },
}));

export interface Props {
  className?: string;
  children: ReactNode;
  innerRef?:
    | ((instance: HTMLDivElement) => void)
    | MutableRefObject<HTMLDivElement | null>
    | null;
}

const GradientContainer: FunctionComponent<Props> = ({
  className,
  children,
  innerRef,
}: Props) => {
  const classes = useStyles();

  return (
    <div ref={innerRef} className={clsx(classes.root, className)}>
      {children}
    </div>
  );
};

export default GradientContainer;
