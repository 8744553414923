import React, { MutableRefObject, FunctionComponent, ReactNode } from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core';
import { DroppableProvided } from 'react-beautiful-dnd';

import GradientContainer from '../GradientContainer';

const useStyles = makeStyles(() => ({
  root: {
    padding: '3.375rem',
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    color: 'white',
    marginBottom: '2rem',
  },
  scrollContainer: {
    flex: '1',
    padding: '2.25rem',
    paddingTop: 0,
    display: 'inline-flex',
    flexDirection: 'column',
    minWidth: '13.75rem',
    boxSizing: 'border-box',
    height: '100%',
  },
  scroll: {
    overflowX: 'hidden',
  },
}));

export interface Props {
  className?: string;
  header: ReactNode;
  children: ReactNode;
  scroll?: boolean;
  droppableProvided?: DroppableProvided;
  innerRef?:
    | ((instance: HTMLDivElement) => void)
    | MutableRefObject<HTMLDivElement | null>
    | null;
}

const ScrollView: FunctionComponent<Props> = ({
  className,
  header,
  children,
  scroll = true,
  droppableProvided,
  innerRef,
}: Props) => {
  const classes = useStyles();

  return (
    <GradientContainer
      className={clsx(classes.root, className)}
      innerRef={innerRef}
    >
      <div className={classes.header}>{header}</div>
      <div
        className={clsx(classes.scrollContainer, scroll && classes.scroll)}
        // eslint-disable-next-line @typescript-eslint/unbound-method
        ref={droppableProvided?.innerRef}
        {...(droppableProvided ? droppableProvided.droppableProps : [])}
      >
        {children}
        {droppableProvided?.placeholder}
      </div>
    </GradientContainer>
  );
};

export default ScrollView;
