import React, { FunctionComponent, ReactNode } from 'react';

import KovachTrailSign from 'components/KovachTrailSign';
import SonoranLogo from 'components/SonoranLogo';

import { makeStyles } from '@material-ui/styles';
import Level1Background from 'assets/level1Background.jpg';
import Level2Background from 'assets/level2Background.jpg';
import Level3Background from 'assets/level3Background.jpg';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    backgroundPosition: 'center',
    flexDirection: 'column',
    color: 'white',
    height: '100%',
    overflowX: 'hidden',
    position: 'relative',
  },
  children: {
    margin: '3rem',
    display: 'flex',
    justifyContent: 'center',
  },
}));

interface Props {
  level: number;
  children: ReactNode;
}

const LevelBackground: FunctionComponent<Props> = ({
  level,
  children,
}: Props) => {
  let background;

  switch (level) {
    case 1:
      background = Level1Background;
      break;
    case 2:
      background = Level2Background;
      break;
    case 3:
      background = Level3Background;
      break;
    default:
      background = Level1Background;
      break;
  }

  const classes = useStyles({ background });

  return (
    <div
      className={classes.root}
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className={classes.children}>{children}</div>
      <KovachTrailSign level={3} />
      <SonoranLogo />
    </div>
  );
};

export default LevelBackground;
