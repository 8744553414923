import React, { FunctionComponent, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Box,
  Typography,
  Paper,
  TextField,
  Snackbar,
  CircularProgress,
} from '@material-ui/core';

import { auth, functions } from 'firebaseApp';

import MenuBackground from 'components/MenuBackground';
import ForgotPasswordDialog from 'components/ForgotPasswordDialog';

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    width: '350px',
    marginTop: '2rem',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  accessCode: {
    marginTop: '1rem',
  },
  login: {
    marginTop: '1rem',
  },
  circularProgress: {
    position: 'absolute',
    right: '-50%',
    bottom: '15%',
  },
}));

const StudentLogin: FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation(['studentLogin', 'common']);
  const [loading, setLoading] = useState(false);
  const [invalidLogin, setInvalidLogin] = useState(false);
  const [unableToLogin, setUnableToLogin] = useState(false);
  const [forgot, setForgot] = useState(false);
  const [teacherName, setTeacherName] = useState('');
  const [randCode, setRandCode] = useState('');

  const handleLogin = useCallback(async () => {
    setLoading(true);
    const getCustomTokenFromCode = functions.httpsCallable(
      'getCustomTokenFromCode',
    );

    let result;
    try {
      result = await getCustomTokenFromCode({
        code: `${teacherName.toUpperCase()}${randCode.toUpperCase()}`,
      });
    } catch (error) {
      setUnableToLogin(true);
      setLoading(false);
    }

    if (!result || result.data === null) {
      setInvalidLogin(true);
      setLoading(false);
      return;
    }

    await auth.signInWithCustomToken(result.data).then((res) => {
      setLoading(false);
      return res;
    });
    history.push('/select-level');
  }, [history, teacherName, randCode]);

  return (
    <>
      <MenuBackground>
        <div className={classes.root}>
          <Typography variant="h4" component="h1">
            {t('LoginWithYourAccessCode')}
          </Typography>
          <Paper className={classes.container}>
            <TextField
              disabled={loading}
              className={classes.accessCode}
              variant="outlined"
              helperText={t('AccessCodeLast4Help')}
              label={t('AccessCodeLast4')}
              value={teacherName}
              onChange={(event: { target: { value: string } }) =>
                setTeacherName(event.target.value)
              }
              inputProps={{ maxLength: 4 }}
            />
            <TextField
              disabled={loading}
              className={classes.accessCode}
              variant="outlined"
              helperText={t('AccessCodeRandomHelp')}
              label={t('AccessCodeRandom')}
              value={randCode}
              onChange={(event: { target: { value: string } }) =>
                setRandCode(event.target.value)
              }
              inputProps={{ maxLength: 4 }}
            />
            <Button onClick={() => setForgot(true)}>
              {t('ForgotAccessCode')}
            </Button>
            <Box position="relative">
              <Button
                disabled={loading}
                className={classes.login}
                variant="contained"
                onClick={handleLogin}
              >
                {t('common:login')}
              </Button>
              {loading && (
                <CircularProgress
                  size={30}
                  className={classes.circularProgress}
                />
              )}
            </Box>
          </Paper>
        </div>
        <Snackbar
          open={invalidLogin}
          autoHideDuration={6000}
          onClose={() => setInvalidLogin(false)}
          message={t('InvalidLogin')}
          style={{ position: 'absolute' }}
        />
        <Snackbar
          open={unableToLogin}
          autoHideDuration={6000}
          onClose={() => setUnableToLogin(false)}
          message={t('UnableToLogin')}
          style={{ position: 'absolute' }}
        />
        <ForgotPasswordDialog open={forgot} onClose={() => setForgot(false)} />
      </MenuBackground>
    </>
  );
};

export default StudentLogin;
