import React, { FunctionComponent } from 'react';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Paper, Typography, CircularProgress } from '@material-ui/core';

import PlayIconButton from 'components/IconButtons/Play';
import RestartIconButton from 'components/IconButtons/Restart';

const useStyles = makeStyles(() => ({
  root: {
    padding: '0.5rem',
    paddingBottom: '0.5rem',
    width: '12.50rem',
    height: '12.50rem',
  },
  name: {
    marginTop: '0.75rem',
  },
  buttonContainer: {
    marginTop: '1.25rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  button: {
    width: '3.5rem',
    height: '3.5rem',
  },
  buttonMarginLeft: {
    marginLeft: '1rem',
  },
}));

export interface Props {
  className?: string;
  name: string;
  onPlay: () => void;
  onRestart?: () => void;
  disabled?: boolean;
  loading?: boolean;
  locked?: boolean;
}

const SelectLevelCard: FunctionComponent<Props> = ({
  className,
  name,
  onRestart,
  onPlay,
  locked,
  disabled,
  loading,
}) => {
  const classes = useStyles();

  return (
    <Paper className={clsx(classes.root, className)}>
      <Typography
        className={classes.name}
        display="block"
        variant="h2"
        component="h2"
        align="center"
      >
        {name}
      </Typography>
      <div className={classes.buttonContainer}>
        {loading && <CircularProgress />}
        {!loading && (
          <>
            <PlayIconButton
              disabled={locked || disabled}
              className={classes.button}
              onClick={onPlay}
            />
            {onRestart && (
              <RestartIconButton
                disabled={locked || disabled}
                className={clsx(classes.button, classes.buttonMarginLeft)}
                onClick={onRestart}
              />
            )}
          </>
        )}
      </div>
    </Paper>
  );
};

export default SelectLevelCard;
