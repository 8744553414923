import React, {
  FunctionComponent,
  ReactNode,
  useState,
  useCallback,
} from 'react';

import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from '@material-ui/core';

import { useHistory } from 'react-router-dom';

import NextStepIconButton from 'components/IconButtons/NextStep';
import BackStepIconButton from 'components/IconButtons/BackStep';
import HomeIconButton from 'components/IconButtons/Home';
import RestartIconButton from 'components/IconButtons/Restart';

const useStyles = makeStyles(() => ({
  actions: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  loading: {
    marginRight: '0.5rem',
  },
}));

export interface Props {
  open: boolean;
  loading?: boolean;
  onClose: () => void;
  onClickRestart?: () => void;
  title: ReactNode;
  ContentStep0: FunctionComponent;
  ContentStep1: FunctionComponent;
}

const SummaryDialog: FunctionComponent<Props> = ({
  open,
  loading,
  onClose,
  onClickRestart,
  title,
  ContentStep0,
  ContentStep1,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [step, setStep] = useState(0);

  const handleClickHome = useCallback(() => {
    history.push('/select-level');
  }, [history]);

  let actions = null;
  if (step === 0) {
    actions = (
      <NextStepIconButton disabled={loading} onClick={() => setStep(1)} />
    );
  } else {
    actions = (
      <>
        {loading && <CircularProgress className={classes.loading} />}
        <BackStepIconButton disabled={loading} onClick={() => setStep(0)} />
        <HomeIconButton disabled={loading} onClick={handleClickHome} />
        {onClickRestart && (
          <RestartIconButton disabled={loading} onClick={onClickRestart} />
        )}
      </>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      disablePortal
      style={{ position: 'absolute' }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {step === 0 ? <ContentStep0 /> : <ContentStep1 />}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>{actions}</DialogActions>
    </Dialog>
  );
};

export default SummaryDialog;
