import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';

import SummaryDialog from 'components/SummaryDialog';

import StatisticsContent from './StatisticsContent';

export interface Props {
  open: boolean;
  onClose: () => void;
}

const ContentStep0: FunctionComponent = () => {
  const { t } = useTranslation(['level3']);
  return <Typography>{t('Summary')}</Typography>;
};

const Level2SummaryDialog: FunctionComponent<Props> = ({ open, onClose }) => {
  const { t } = useTranslation(['level3']);

  return (
    <SummaryDialog
      title={t('Level3Summary')}
      ContentStep0={ContentStep0}
      ContentStep1={StatisticsContent}
      open={open}
      onClose={onClose}
    />
  );
};

export default Level2SummaryDialog;
