import { FunctionComponent, SyntheticEvent, useState } from 'react';

import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  Paper,
  Typography,
  Avatar,
  Menu,
  MenuItem,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    padding: '1rem',
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatar: {
    width: '3rem',
    height: '3rem',
  },
  content: {
    marginLeft: '1.25rem',
  },
}));

export interface Props {
  className?: string;
  src?: string;
  title: string;
  subtitle: string;
  onLogout?: () => void;
}

const UserCard: FunctionComponent<Props> = ({
  className,
  src,
  title,
  subtitle,
  onLogout,
}) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  return (
    <>
      <Paper
        aria-controls="user-menu"
        aria-haspopup="true"
        className={clsx(classes.root, className)}
        onMouseEnter={(event: SyntheticEvent) =>
          setAnchorEl(event.currentTarget)
        }
      >
        <Avatar className={classes.avatar} variant="rounded" src={src} />
        <div className={classes.content}>
          <Typography variant="h5">{title}</Typography>
          <Typography variant="body1">{subtitle}</Typography>
        </div>
      </Paper>
      <Menu
        id="user-menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={onLogout}>{t('Logout')}</MenuItem>
      </Menu>
    </>
  );
};

export default UserCard;
