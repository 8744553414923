import { ReactElement, useState, useEffect } from 'react';

import { plainToClass } from 'class-transformer';
import {
  OrganismId,
  UserAvatar,
  Student,
  IAvatar,
} from '@sonoran-ecosystems/types';

import { auth, database, functions, storage } from 'firebaseApp';

import staticAvatarData from 'data/avatar';

import { log } from 'utils/log';

import Dialog from '../Dialog';

export interface Props {
  open: boolean;
  onClose: () => void;
}

const DialogWithListener = ({ open, onClose }: Props): ReactElement => {
  const [userAvatars, setUserAvatars] = useState<UserAvatar[]>([]);
  const [countCurrency, setCountCurrency] = useState<number>(0);
  const [avatars, setAvatars] = useState<IAvatar[]>([]);

  useEffect(() => {
    async function execute(): Promise<void> {
      const avatarsWithImages = await Promise.all(
        staticAvatarData.map(async (item) => {
          let downloadUrl;
          try {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            downloadUrl = await storage.ref(item.src).getDownloadURL();
          } catch (error) {
            log(error);
          }

          return {
            ...item,
            src: downloadUrl as string,
          };
        }),
      );

      setAvatars(avatarsWithImages);
    }

    execute().catch(log);
  }, []);

  useEffect(() => {
    const uid = auth.currentUser?.uid;

    if (!uid) {
      return undefined;
    }

    const unsubscribe = database
      .collection('students')
      .doc(uid)
      .onSnapshot((snapshot) => {
        if (!snapshot.exists) {
          return;
        }

        const student = plainToClass(Student, snapshot.data());
        setCountCurrency(student.currency || 0);
      });

    return unsubscribe;
  }, []);

  useEffect(() => {
    const uid = auth.currentUser?.uid;

    if (!uid) {
      return undefined;
    }

    const unsubscribe = database
      .collection('students')
      .doc(uid)
      .collection('avatars')
      .onSnapshot((snapshot) => {
        setUserAvatars(
          snapshot.docs.map((item) => plainToClass(UserAvatar, item.data())),
        );
      });

    return unsubscribe;
  }, []);

  async function handlePurchaseAvatar(id: OrganismId): Promise<void> {
    const callable = functions.httpsCallable('purchaseAvatar');
    await callable(id);
  }

  async function handleSelectAvatar(id: OrganismId): Promise<void> {
    const callable = functions.httpsCallable('selectAvatar');
    await callable(id);
  }

  return (
    <Dialog
      open={open}
      countCurrency={countCurrency}
      onClose={onClose}
      avatars={avatars}
      userAvatars={userAvatars}
      onPurchaseAvatar={handlePurchaseAvatar}
      onSelectAvatar={handleSelectAvatar}
    />
  );
};

export default DialogWithListener;
