import { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';

import {
  Card,
  makeStyles,
  CardContent,
  Typography,
  Button,
  CircularProgress,
} from '@material-ui/core';

import { IAvatar, UserAvatar, AvatarStatus } from '@sonoran-ecosystems/types';

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-flex',
  },
  content: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  img: {
    marginLeft: '1rem',
    height: '5rem',
    width: '5rem',
    objectFit: 'cover',
    borderRadius: '0.5rem',
  },
}));

export interface Props {
  selecting?: boolean;
  purchasing?: boolean;
  disabled?: boolean;
  userAvatar?: UserAvatar;
  avatar: IAvatar;
  onBuy: () => Promise<void>;
  onSelect: () => Promise<void>;
}

const AvatarCard = ({
  avatar,
  userAvatar,
  onBuy,
  onSelect,
  selecting,
  purchasing,
  disabled,
}: Props): ReactElement => {
  const { t } = useTranslation('shop');
  const classes = useStyles();

  let action;
  if (userAvatar) {
    switch (userAvatar.status) {
      case AvatarStatus.BUY:
        if (purchasing) {
          action = <CircularProgress size={24} />;
        } else {
          action = (
            <Button disabled={disabled} onClick={onBuy} variant="contained">
              {t('buy')}
            </Button>
          );
        }
        break;
      case AvatarStatus.PURCHASED:
        if (selecting) {
          action = <CircularProgress size={24} />;
        } else {
          action = (
            <Button disabled={disabled} onClick={onSelect} variant="contained">
              {t('select')}
            </Button>
          );
        }
        break;
      case AvatarStatus.ACTIVE:
        action = <Typography>{t('Active')}</Typography>;
        break;
      default:
        break;
    }
  } else if (purchasing) {
    action = <CircularProgress size={24} />;
  } else {
    action = (
      <Button disabled={disabled} onClick={onBuy} variant="contained">
        {t('buy')}
      </Button>
    );
  }

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <div className={classes.left}>
          <Typography variant="h6" component="h6">
            {t('PricklyPearsWithValue', { value: avatar.cost })}
          </Typography>
          <div className={classes.actions}>{action}</div>
        </div>
        <img className={classes.img} src={avatar.src} alt={avatar.id} />
      </CardContent>
    </Card>
  );
};

export default AvatarCard;
