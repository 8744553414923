import { ReactNode, FunctionComponent, useCallback } from 'react';

import { DragDropContext, DropResult } from 'react-beautiful-dnd';

// hooks
import useLevelState from 'hooks/useLevelState';

// types
import DroppableId from 'types/enums/DroppableId';

interface Props {
  children: ReactNode;
}

const DragDropContainer: FunctionComponent<Props> = ({ children }) => {
  const { placeThingIntoLiving, placeThingIntoNonliving } = useLevelState();

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      switch (result.destination.droppableId) {
        case DroppableId.LEVEL1_SCROLL_VIEW_LIVING:
          placeThingIntoLiving(result.destination.index);
          break;
        case DroppableId.LEVEL1_SCROLL_VIEW_NONLIVING:
          placeThingIntoNonliving(result.destination.index);
          break;
        default:
          break;
      }
    },
    [placeThingIntoNonliving, placeThingIntoLiving],
  );

  return (
    <DragDropContext onDragEnd={handleDragEnd}>{children}</DragDropContext>
  );
};

export default DragDropContainer;
