import { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Paper,
  Typography,
  LinearProgress,
  Button,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';

import { AchievementStatus } from '@sonoran-ecosystems/types';

import { CheckIcon } from 'utils/iconMappings';

const useStyles = makeStyles(() => ({
  root: {
    padding: '0.75rem 1rem',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  linearProgress: {
    marginTop: '0.5rem',
  },
  progressRoot: {
    borderRadius: '0.4rem',
    height: '0.8rem',
    backgroundColor: '#eff1f8',
  },
  bar1Determinate: {
    background: 'linear-gradient(180deg, #CE4C51 0%, #C22024 100%)',
    borderRadius: '0.4rem',
    bottom: 1,
  },
  right: {
    marginRight: '1rem',
  },
  claimRewardContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  claimButton: {
    marginLeft: '1rem',
  },
}));

export interface Props {
  title: string;
  subtitle: string;
  progress?: number;
  claiming?: boolean;
  disabled?: boolean;
  status: AchievementStatus;
  onClaim: () => void;
  children?: ReactNode;
}

const Card = ({
  title,
  subtitle,
  progress,
  claiming,
  disabled,
  status,
  onClaim,
  children,
}: Props): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();

  let rightContent = null;
  switch (status) {
    case AchievementStatus.CLAIMED:
      rightContent = <CheckIcon fontSize="large" />;
      break;
    case AchievementStatus.UNCLAIMED:
      rightContent = (
        <div className={classes.claimRewardContainer}>
          {children}
          {claiming ? (
            <CircularProgress className={classes.claimButton} size={24} />
          ) : (
            <Button
              className={classes.claimButton}
              variant="contained"
              disabled={disabled}
              onClick={onClaim}
            >
              {t('claim')}
            </Button>
          )}
        </div>
      );
      break;
    case AchievementStatus.INPROGRESS:
      rightContent = children;
      break;
    default:
      break;
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.contentContainer}>
        <div>
          <Typography variant="h6">{title}</Typography>
          <Typography>{subtitle}</Typography>
        </div>
        <div className={classes.right}>{rightContent}</div>
      </div>
      {(progress || progress === 0) && status === AchievementStatus.INPROGRESS && (
        <LinearProgress
          className={classes.linearProgress}
          value={progress}
          variant="determinate"
          classes={{
            root: classes.progressRoot,
            bar1Determinate: classes.bar1Determinate,
          }}
        />
      )}
    </Paper>
  );
};

export default Card;
