import i18n from 'i18next';
import { AchievementName, ConnectionType } from '@sonoran-ecosystems/types';

import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    common: {
      WebOfLife: 'Web of Life',
      login: 'login',
      submit: 'submit',
      okay: 'okay',
      FirstTries: 'First Tries',
      Points: 'Points',
      remove: 'remove',
      Logout: 'Logout',
      LoadingLevel: 'Loading Level',
      Achievements: 'Achievements',
      BackStep: 'Back',
      NextStep: 'Next',
      Close: 'Close',
      Home: 'Home',
      Play: 'Play',
      Restart: 'Restart',
      Submit: 'Submit To Teacher',
      Shop: 'Shop',
      Support: 'Support',
      Summary: 'Summary',
      NextLevelNotUnlocked:
        'To unlock the next level, you need {{ value }} points. Try again!',
      NextLevelUnlocked:
        'Congrats! You unlocked the next level. It is playable from the home page.',
    },
    connectionQuestion: {
      Title: 'What kind of connection is this?',
      submit: 'submit',
      [ConnectionType.FOOD_WATER]: 'Food or Water',
      [ConnectionType.POLLINATION]: 'Pollination',
      [ConnectionType.SHELTER]: 'Shelter',
    },
    shop: {
      Shop: 'Shop',
      buy: 'buy',
      select: 'select',
      Active: 'Active',
      PricklyPearsWithValue: '{{value}} Prickly Pears',
    },
    support: {
      ProgressBar: 'Progress Bar',
      ProgressBarSupport:
        'Shows how many points you have. Fill it all the way to get all the points! Fill it to the first line to unlock the next level.',
      PointBadge: 'Point Badge',
      PointBadgeSupport:
        'Shows how many points you can get for correctly placing the species into living or non-living.',
      HowToPlay: 'How To Play',
      ThingCardSupport:
        'Represents a species. Drag and release the species tile over to the living or nonliving box to place it!',
      ScrollViewSupport:
        'Species in here have not been placed onto the web yet. Click on them to place on empty tiles and create connections.',
      EmptyTileSupport:
        'This is an empty tile. Click place to put a species on it.',
      ThingCardPlacedSupport:
        'Represents a species. Click on it to show more information!',
    },
    achievement: {
      claim: 'claim',
      Achievements: 'Achievements',
      PricklyPearsWithValue: '{{value}} Prickly Pears',
      Completed: {
        [AchievementName.FINISH_ALL_LEVELS]: 'Completed Finish All Levels!',
        [AchievementName.FIVE_FIRST_TRIES]: 'Completed Five First Tries!',
        [AchievementName.PERFECT_GAME]: 'Completed Perfect Game!',
        [AchievementName.PERFECT_LEVEL_1]: 'Completed Perfect Level 1!',
        [AchievementName.PERFECT_LEVEL_2]: 'Completed Perfect Level 2!',
      },
    },
    selectLogin: {
      LoginToYourAccount: 'Login to your Account',
    },
    studentLogin: {
      LoginWithYourAccessCode: 'Login with your Access Code',
      AccessCode: 'Access Code',
      AccessCodeLast4: "Teacher's Last Name",
      AccessCodeLast4Help:
        "First four characters of Teacher's last name. (Example: Mrs. Jones = JONE)",
      AccessCodeRandom: 'Assigned Code',
      AccessCodeRandomHelp:
        'Random four characters assigned to you. (Example: 1A2B)',
      InvalidLogin: 'Incorrect access code.',
      UnableToLogin: 'Unable to login. Please try again.',
      ForgotAccessCode: 'Forgot Access Code?',
      ForgotAccessCodeTitle: 'Forgot Access Code',
      ForgotAccessCodeContent:
        'Please contact your teacher to retrieve your access code.',
    },
    levelSelect: {
      SelectALevel: 'Select a Level',
    },
    levelToolbar: {
      ValuePoints: '{{value}} Points',
      tutorial:
        'This is your toolbar. It has a progress bar with your points. The black line in the bar is how many points you need to unlock the next level. Get points by placing living or nonliving things in the correct column and answering questions!',
      tutorialBadge:
        'This is a point badge. The 100 is how many points you can earn!',
      okay: 'okay',
    },
    level1: {
      Living: 'Living',
      Nonliving: 'Nonliving',
      Level1Summary: 'Level 1 Summary',
      DragLeftOrRight: 'Drag Left or Right',
      Summary:
        'Both living and nonliving things make up an ecosystem. Everything has a role to play and is important to the rest of the ecosystem.',
      IntroductionTitle: 'Level 1',
      IntroductionContent:
        'What are the different pieces of an ecosystem? In this level, you will explore parts of an ecosystem and learn about why each part is important.',
    },
    level2: {
      Organisms: 'Species',
      place: 'place',
      Facts: 'Facts',
      Hint: 'Hint',
      back: 'back',
      Level2Summary: 'Level 2 Summary',
      Summary:
        'Plants and animals are connected in a healthy ecosystem. These connections make the ecosystem like a web, where each thread is an important relationship between two species.',
      IntroductionTitle: 'Level 2',
      IntroductionContent:
        'We have learned about the living and nonliving parts of an ecosystem.  Now, we’ll look at how different Sonoran Desert plants and animals are connected!',
      Level2Tutorial: 'How to play',
      Tutorial: 'For this level .',
      PlaceTutorial:
        'When you think you have found the correct location for the species in the web, click the "Place" button.',
    },
    level3: {
      close: 'close',
      submit: 'submit',
      connect: 'connect',
      disconnect: 'disconnect',
      SubmitToTeacher: 'Submit To Teacher',
      SubmitToTeacherHelp: `Now that all the tiles have species placed on them, you can submit the level to your teacher.`,
      SubmitToTeacherHelpButton:
        'You will see this button in the top left corner of the game. Click it when you are ready to submit.',
      confirm: 'confirm',
      cancel: 'cancel',
      ConfirmSubmitToTeacher:
        'Are you sure you want to submit the level to your teacher? The level will be locked until your teacher grades it.',
      Grade: 'Grade',
      TeachersNotes: `Teacher's Notes`,
      Level3Summary: 'Level 3 Summary',
      Summary:
        'Congratulations! You have just created an ecosystem web. Check to make sure you have made all the connections you want and then hit submit!',
      IntroductionTitle: 'Level 3',
      IntroductionContent:
        'There are many relationships between different species in an ecosystem. Now, it is your turn to take what you have learned and create your own ecosystem web!',
      Level3Tutorial: 'How to play',
      PlaceTutorial:
        'When you are ready to place the species on the web, click the "Place" button',
      ConnectTutorial:
        'To connect two species on the web, click on one of species pictures on your web. Next, find the species you would like your selected species to connect to and click the "Connect" button.',
      DisconnectTutorial:
        'To get rid of a connection follow the same steps but this time the button will say "Disconnect".',
    },
  },
};

i18n
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'development',
    resources,
    lng: 'en',
    interpolation: { escapeValue: false },
  })
  .catch((error) => {
    // eslint-disable-next-line no-console
    console.log(error);
  });

export default i18n;
