import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from '@material-ui/core';

import {
  AvatarStatus,
  OrganismId,
  UserAvatar,
  IAvatar,
} from '@sonoran-ecosystems/types';

import CloseIconButton from 'components/IconButtons/Close';

import AvatarCard from '../Card';

const useStyles = makeStyles(() => ({
  titleContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  avatarCardContainer: {
    marginBottom: '1rem',
    marginTop: '1rem',
    flex: '0 40%',
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
}));

export interface Props {
  userAvatars: UserAvatar[];
  avatars: IAvatar[];
  countCurrency?: number;
  onPurchaseAvatar: (id: OrganismId) => Promise<void>;
  onSelectAvatar: (id: OrganismId) => Promise<void>;
  open: boolean;
  onClose: () => void;
}

const ShopDialog = ({
  userAvatars,
  avatars,
  onPurchaseAvatar,
  onSelectAvatar,
  open,
  onClose,
  countCurrency,
}: Props): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation('shop');
  const [selecting, setSelecting] = useState<OrganismId | undefined>(undefined);
  const [purchasing, setPurchasing] = useState<OrganismId | undefined>(
    undefined,
  );

  async function handleBuy(id: OrganismId): Promise<void> {
    setPurchasing(id);
    await onPurchaseAvatar(id);
    setPurchasing(undefined);
  }

  async function handleSelect(id: OrganismId): Promise<void> {
    setSelecting(id);
    await onSelectAvatar(id);
    setSelecting(undefined);
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      disablePortal
      style={{ position: 'absolute' }}
    >
      <DialogTitle disableTypography>
        <div className={classes.titleContainer}>
          <Typography variant="h4" component="h6">
            {t('Shop')}
          </Typography>
          <Typography variant="h6" component="h6">
            {t('PricklyPearsWithValue', { value: countCurrency || 0 })}
          </Typography>
          <CloseIconButton onClick={onClose} />
        </div>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {avatars.map((avatar) => {
          const userAvatar = userAvatars.find((item) => item.id === avatar.id);

          let disabled = false;
          if (selecting) {
            disabled = selecting !== avatar.id;
          }

          if (!disabled && purchasing) {
            disabled = purchasing !== avatar.id;
          }

          if (!disabled) {
            if (!userAvatar || userAvatar.status === AvatarStatus.BUY) {
              // disable if they can't purchase
              disabled = !countCurrency || countCurrency < avatar.cost;
            }
          }

          return (
            <div key={avatar.id} className={classes.avatarCardContainer}>
              <AvatarCard
                key={avatar.id}
                avatar={avatar}
                userAvatar={userAvatar}
                selecting={selecting === avatar.id}
                purchasing={purchasing === avatar.id}
                disabled={disabled}
                onBuy={() => handleBuy(avatar.id)}
                onSelect={() => handleSelect(avatar.id)}
              />
            </div>
          );
        })}
      </DialogContent>
    </Dialog>
  );
};

export default ShopDialog;
