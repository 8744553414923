import React, { FunctionComponent, useCallback } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Typography, Paper, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import MenuBackground from "../../../components/MenuBackground";

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    marginTop: "2rem",
    padding: "1rem",
  },
  row: {
    padding: "1rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "400px",
  },
  top: {
    justifyContent: "flex-start",
  },
  bottom: {
    justifyContent: "flex-end",
  },
  card: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
  },
  cardButton: {
    marginTop: "1rem",
  },
}));

const SelectLogin: FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation(["selectLogin", "common"]);

  const selectStudent = useCallback(() => {
    history.push("/login/student");
  }, [history]);

  return (
    <MenuBackground>
      <div className={classes.root}>
        <Typography variant="h4" component="h1">
          {t("LoginToYourAccount")}
        </Typography>
        <Paper className={classes.container}>
          <div className={clsx(classes.row, classes.top)}>
            <Paper className={classes.card}>
              <Typography variant="h6" component="h6">
                {t("Student")}
              </Typography>
              <Button
                className={classes.cardButton}
                variant="contained"
                onClick={selectStudent}
              >
                {t("login")}
              </Button>
            </Paper>
          </div>
          <div className={clsx(classes.row, classes.bottom)}>
            <Paper className={classes.card}>
              <Typography variant="h6" component="h6">
                {t("Teacher")}
              </Typography>
              <Button className={classes.cardButton} variant="contained">
                {t("common:login")}
              </Button>
            </Paper>
          </div>
        </Paper>
      </div>
    </MenuBackground>
  );
};

export default SelectLogin;
