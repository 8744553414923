import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import useLevelState from 'hooks/useLevelState';
import { makeStyles } from '@material-ui/styles';

import { Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  text: {
    textAlign: 'center',
  },
}));

const StatisticsContent: FunctionComponent = () => {
  const { t } = useTranslation(['common']);
  const classes = useStyles();
  const { level2State } = useLevelState();

  let nextLevelHelperText = 'NextLevelUnlocked';
  if (level2State && level2State.points >= level2State.unlockPoints) {
    nextLevelHelperText = 'NextLevelUnlocked';
  }

  return (
    <Grid container spacing={3} alignItems="center" justify="center">
      <Grid item xs={12}>
        <Typography>
          {t(nextLevelHelperText, { value: level2State?.unlockPoints })}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" className={classes.text}>
          {t('Points')}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" className={classes.text}>
          {level2State?.points || t('N/A')}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" className={classes.text}>
          {t('FirstTries')}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" className={classes.text}>
          {level2State ? level2State.firstTries : 'N/A'}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default StatisticsContent;
