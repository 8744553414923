import {
  IThing,
  ILivingThing,
  OrganismId,
  IConnection,
  ConnectionType,
} from '@sonoran-ecosystems/types';

interface Data {
  living: ILivingThing[];
  nonliving: IThing[];
}

export const level2Connections: IConnection[] = [
  {
    ids: [OrganismId.HARRIS_HAWK, OrganismId.ANTELOPE_SQUIRREL],
    type: ConnectionType.FOOD_WATER,
  },
  {
    ids: [OrganismId.ANTELOPE_SQUIRREL, OrganismId.IRONWOOD_TREE],
    type: ConnectionType.FOOD_WATER,
  },
  {
    ids: [OrganismId.ANTELOPE_SQUIRREL, OrganismId.BOBCAT],
    type: ConnectionType.FOOD_WATER,
  },
  {
    ids: [OrganismId.POCKET_MOUSE, OrganismId.BOBCAT],
    type: ConnectionType.FOOD_WATER,
  },
  {
    ids: [OrganismId.POCKET_MOUSE, OrganismId.IRONWOOD_TREE],
    type: ConnectionType.FOOD_WATER,
  },
  {
    ids: [OrganismId.IRONWOOD_TREE, OrganismId.HUMMINGBIRD],
    type: ConnectionType.POLLINATION,
  },
  {
    ids: [OrganismId.IRONWOOD_TREE, OrganismId.BEE],
    type: ConnectionType.POLLINATION,
  },
  {
    ids: [OrganismId.IRONWOOD_TREE, OrganismId.SAGUARO],
    type: ConnectionType.SHELTER,
  },
  {
    ids: [OrganismId.IRONWOOD_TREE, OrganismId.WHITE_WINGED_DOVE],
    type: ConnectionType.FOOD_WATER,
  },
  {
    ids: [OrganismId.HUMMINGBIRD, OrganismId.SAGUARO],
    type: ConnectionType.POLLINATION,
  },
  {
    ids: [OrganismId.BOBCAT, OrganismId.WHITE_WINGED_DOVE],
    type: ConnectionType.FOOD_WATER,
  },
  {
    ids: [OrganismId.BEE, OrganismId.SAGUARO],
    type: ConnectionType.POLLINATION,
  },
  {
    ids: [OrganismId.SAGUARO, OrganismId.WHITE_WINGED_DOVE],
    type: ConnectionType.POLLINATION,
  },
  {
    ids: [OrganismId.SAGUARO, OrganismId.ELF_OWL],
    type: ConnectionType.SHELTER,
  },
  {
    ids: [OrganismId.SAGUARO, OrganismId.CACTUS_LONGHORN_BEETLE],
    type: ConnectionType.FOOD_WATER,
  },
  {
    ids: [OrganismId.ELF_OWL, OrganismId.CACTUS_LONGHORN_BEETLE],
    type: ConnectionType.FOOD_WATER,
  },
  {
    ids: [OrganismId.ELF_OWL, OrganismId.TARANTULA],
    type: ConnectionType.FOOD_WATER,
  },
  {
    ids: [OrganismId.TARANTULA, OrganismId.CACTUS_LONGHORN_BEETLE],
    type: ConnectionType.FOOD_WATER,
  },
];

const level1Data: Data = {
  living: [
    {
      id: OrganismId.HARRIS_HAWK,
      name: `Harris's Hawk`,
      src: 'living/harris-hawk.jpg',
      tileHint:
        'This Sonoran Desert bird eats other, smaller animals. It hunts in the daytime.',
      connections: [OrganismId.ANTELOPE_SQUIRREL],
      facts: [
        `The Harris's hawk has great eyesight, which allows it to see its small prey from far away.`,
        `The Harris's hawks hunt in groups, unlike a lot of other types of hawks.`,
      ],
      question: {
        text: `Why are Harris's hawks important to the Sonoran Desert?`,
        answers: [
          {
            id: 1,
            text: 'They eat seeds and poop them out in other places',
          },
          {
            id: 2,
            text:
              'They eat rodents which helps keep rodent populations from getting too big and spreading diseases',
            correct: true,
          },
          {
            id: 3,
            text: 'They are cool to look at',
          },
          {
            id: 4,
            text: 'All of the above',
          },
        ],
      },
    },
    {
      id: OrganismId.ANTELOPE_SQUIRREL,
      name: 'Antelope Squirrel',
      src: 'living/antelope-squirrel.jpg',
      tileHint:
        'This Sonoran Desert rodent is prey for carnivores. It eats the seeds of different desert trees.',
      connections: [
        OrganismId.HARRIS_HAWK,
        OrganismId.IRONWOOD_TREE,
        OrganismId.BOBCAT,
      ],
      facts: [
        `Antelope squirrels are active most of the day, despite hot temperatures. To cool down, they lay on their bellies on a cool surface to release heat.`,
        `Despite the spines, antelope squirrels will climb up cactuses, like the barrel cactus, to reach the fruit.`,
      ],
      question: {
        text:
          'Why are antelope squirrels an important part of the Sonoran Desert ecosystem?',
        answers: [
          {
            id: 1,
            text: 'They eat a lot of plant seeds',
          },
          {
            id: 2,
            text: 'They are prey for many different predators',
            correct: true,
          },
          {
            id: 3,
            text: 'They live in burrows',
          },
          {
            id: 4,
            text: 'All of the above',
          },
        ],
      },
    },
    {
      id: OrganismId.POCKET_MOUSE,
      name: 'Pocket Mouse',
      src: 'living/pocket-mouse.jpg',
      tileHint:
        'This Sonoran Desert rodent is prey for nighttime hunters. The seeds it eats are from different desert trees provides both food and water.',
      connections: [OrganismId.BOBCAT, OrganismId.IRONWOOD_TREE],
      facts: [
        `Pocket mice have pouchees in their mouths that allow them to carry lots of seeds once back to their burrows.`,
        `Pocket mice come out at night to avoid high desert temperatures and daytime predators.`,
      ],
      question: {
        text:
          'Why are Pocket mice an important part of the Sonoran Desert ecosystem?',
        answers: [
          {
            id: 1,
            text: 'They eat a lot of plant seeds',
          },
          {
            id: 2,
            text: 'They are prey for many different predators',
          },
          {
            id: 3,
            text: 'They live in burrows',
          },
          {
            id: 4,
            text: 'All of the above',
            correct: true,
          },
        ],
      },
    },
    {
      id: OrganismId.IRONWOOD_TREE,
      name: 'Ironwood Tree',
      src: 'living/ironwood.jpg',
      tileHint:
        'This Sonoran Desert plant protects new desert plants trying to grow. Like many plants, it requires other animals to pollinate it.',
      connections: [
        OrganismId.ANTELOPE_SQUIRREL,
        OrganismId.POCKET_MOUSE,
        OrganismId.HUMMINGBIRD,
        OrganismId.BEE,
        OrganismId.SAGUARO,
        OrganismId.WHITE_WINGED_DOVE,
      ],
      facts: [
        `Ironwood trees can provide important shade for young plants trying to survive hot summer temperatures.`,
        `Ironwood trees produce seeds every year that are food for many small rodents and birds.`,
      ],
      question: {
        text:
          'What is one way ironwood trees are an important part of the Sonoran Desert ecosystem?',
        answers: [
          {
            id: 1,
            text: 'Their roots grow very deep',
          },
          {
            id: 2,
            text: 'They give shade to new plants trying to grow',
            correct: true,
          },
          {
            id: 3,
            text: 'When ironwoods produce flowers it signals rain is coming',
          },
          {
            id: 4,
            text: 'All of the above',
          },
        ],
      },
    },
    {
      id: OrganismId.HUMMINGBIRD,
      name: 'Hummingbird',
      src: 'living/hummingbird.jpg',
      tileHint: 'This Sonoran Desert bird drinks plant nectar for energy.',
      connections: [OrganismId.IRONWOOD_TREE, OrganismId.SAGUARO],
      facts: [
        `Hummingbirds drink nectar to supply most of their energy.`,
        `Hummingbirds don't have any natural predators because they are so fast and don't make a very filling meal.`,
      ],
      question: {
        text:
          'Why are hummingbirds an important part of the Sonoran Desert ecosystem?',
        answers: [
          {
            id: 1,
            text: 'They pollinate many different plants',
            correct: true,
          },
          {
            id: 2,
            text: 'They build small nests in trees',
          },
          {
            id: 3,
            text: 'They are eaten by many other animals',
          },
          {
            id: 4,
            text: 'All of the above',
          },
        ],
      },
    },
    {
      id: OrganismId.BOBCAT,
      name: 'Bobcat',
      src: 'living/bobcat.jpg',
      tileHint:
        'This Sonoran Desert mammal is a carnivore, meaning it eats other animals. It hunts both during the day and night.',
      connections: [
        OrganismId.ANTELOPE_SQUIRREL,
        OrganismId.WHITE_WINGED_DOVE,
        OrganismId.POCKET_MOUSE,
      ],
      facts: [
        `Bobcats are carnivores, meaning they eat other animals.`,
        `Because bobcats are smaller in size, they can be the prey of other, larger carnivores in the desert.`,
      ],
      question: {
        text:
          'What is one way bobcats are important to the Sonoran Desert ecosystem?',
        answers: [
          {
            id: 1,
            text:
              'Bobcat pee is really smelly and small prey will roll in it to scare off other predators',
          },
          {
            id: 2,
            text: 'They are territorial and fight other bobcats',
          },
          {
            id: 3,
            text:
              'Bobcats help keep rodent populations in check and free of disease by eating them',
            correct: true,
          },
          {
            id: 4,
            text: 'All of the above',
          },
        ],
      },
    },
    {
      id: OrganismId.BEE,
      name: 'Bee',
      src: 'living/bee.jpg',
      tileHint:
        'This Sonoran Desert bug drinks nectar and collects pollen from different plants.',
      connections: [OrganismId.IRONWOOD_TREE, OrganismId.SAGUARO],
      facts: [
        `There are many species of bees that live in the Sonoran Desert and most of the species live alone and not in hives.`,
        `Without bees to polinate them, many desert plants would die.`,
      ],
      question: {
        text: 'Why are bees an important part of the Sonoran Desert ecosystem?',
        answers: [
          {
            id: 1,
            text: 'They are the main type of pollinator for many plants',
            correct: true,
          },
          {
            id: 2,
            text: 'They sting other animals',
          },
          {
            id: 3,
            text: 'They live in hives',
          },
          {
            id: 4,
            text: 'All of the above',
          },
        ],
      },
    },
    {
      id: OrganismId.SAGUARO,
      name: 'Saguaro',
      src: 'living/saguaro.jpg',
      tileHint:
        'Some small bugs lay their eggs in this Sonoran Desert Plant. When the baby bugs hatch, they will eat the saguaro for food.',
      connections: [
        OrganismId.BEE,
        OrganismId.HUMMINGBIRD,
        OrganismId.IRONWOOD_TREE,
        OrganismId.WHITE_WINGED_DOVE,
        OrganismId.ELF_OWL,
        OrganismId.CACTUS_LONGHORN_BEETLE,
      ],
      facts: [
        `The saguaro is the largest cactus species in the United States.`,
        `The saguaro fruit produced each summer is an important part of many animal's diets because it provides nutrients and water during a harsh time of year.`,
      ],
      question: {
        text:
          'What is one reason saguaros are important in the Sonoran Desert ecosystem?',
        answers: [
          {
            id: 1,
            text:
              'Their fruit is eaten by many different animals during the hot summer',
            correct: true,
          },
          {
            id: 2,
            text: `Only bats pollinate the saguaro's flowers`,
          },
          {
            id: 3,
            text: 'The saguaro provides lots of shade',
          },
          {
            id: 4,
            text: 'All of the above',
          },
        ],
      },
    },
    {
      id: OrganismId.ELF_OWL,
      name: 'Elf Owl',
      src: 'living/elf-owl.jpg',
      tileHint:
        'This Sonoran Desert owl preys on bugs and small reptiles. To protect its babies, these owls will build nests inside a specific Sonoran Desert plant.',
      connections: [
        OrganismId.CACTUS_LONGHORN_BEETLE,
        OrganismId.SAGUARO,
        OrganismId.TARANTULA,
      ],
      facts: [
        `Elf owls have really good eyesight and hearing which allow them to hunt prey at night.`,
        `Because these owls are small (about the size of a sparrow), they mainly hunt bugs.`,
      ],
      question: {
        text:
          'Why are elf owls an important part of the Sonoran Desert ecosystem?',
        answers: [
          {
            id: 1,
            text: 'Elf owls are the only owls to burrow in the ground',
          },
          {
            id: 2,
            text: `They help control small prey populations to make sure these populations don't get too big for the ecosystem`,
            correct: true,
          },
          {
            id: 3,
            text: 'Their feathers provide nest material for other birds',
          },
          {
            id: 4,
            text: 'All of the above',
          },
        ],
      },
    },
    {
      id: OrganismId.TARANTULA,
      name: 'Tarantula',
      src: 'living/tarantula.jpg',
      tileHint: 'This Sonoran Desert spider is both predator and prey.',
      connections: [OrganismId.ELF_OWL, OrganismId.CACTUS_LONGHORN_BEETLE],
      facts: [
        `Tarantulas hunt at night and are carnivores.`,
        `Even though they have eight eyes, tarantulas use vibrations from the ground to sense both prey and predators.`,
      ],
      question: {
        text: 'Why are tarantulas important to the Sonoran Desert ecosystem?',
        answers: [
          {
            id: 1,
            text: 'Tarantula poop is eaten by different ants',
          },
          {
            id: 2,
            text:
              'They eat a lot of bugs and help keep those populations balanced',
            correct: true,
          },
          {
            id: 3,
            text: `Birds use the tarantula's hair to build nests`,
          },
          {
            id: 4,
            text: 'All of the above',
          },
        ],
      },
    },
    {
      id: OrganismId.CACTUS_LONGHORN_BEETLE,
      name: 'Cactus Longhorn Beetle',
      src: 'living/cactus-longhorn-beetle.jpg',
      tileHint:
        'This Sonoran Desert bug hatches inside a specific Sonoran Desert plant and eats it for food. It is prey for spiders and owls.',
      connections: [
        OrganismId.ELF_OWL,
        OrganismId.TARANTULA,
        OrganismId.SAGUARO,
      ],
      facts: [
        `These beetles eat different parts of cactus for food.`,
        `Like many other Sonoran Desert bugs, the cactus longhorn beetle provides a meal for many carnivores and omnivorous animals.`,
      ],
      question: {
        text:
          'How are cactus longhorn beetles important to the Sonoran Desert ecosystem?',
        answers: [
          {
            id: 1,
            text:
              'These beetles reproduce quickly and provide a regular food source for many animals',
            correct: true,
          },
          {
            id: 2,
            text:
              'These beetles harvest the poop of other animals and keep the desert clean',
          },
          {
            id: 3,
            text:
              'Pocket mice collect the shiny shells of dead cactus longhorn beetles',
          },
          {
            id: 4,
            text: 'All of the above',
          },
        ],
      },
    },
    {
      id: OrganismId.WHITE_WINGED_DOVE,
      name: 'White-winged Dove',
      src: 'living/white-winged-dove.jpg',
      tileHint: 'This Sonoran Desert bird is prey for carnivorous mammals.',
      connections: [
        OrganismId.SAGUARO,
        OrganismId.BOBCAT,
        OrganismId.IRONWOOD_TREE,
      ],
      facts: [
        `Like many birds, the white-winged dove mainly eats different types of plant seeds.`,
        `White-winged doves help disperse seeds by eating the seeds and pooping them out at a different location.`,
      ],
      question: {
        text:
          'Why is the white-winged dove an important part of the Sonoran Desert ecosystem?',
        answers: [
          {
            id: 1,
            text:
              'White-winged doves are pollinators for saguaros in the summer.',
            correct: true,
          },
          {
            id: 2,
            text:
              'White-winged doves make noise when they take off to alert other doves of danger',
          },
          {
            id: 3,
            text:
              'White-winged doves make sturdy nests that are used by other birds',
          },
          {
            id: 4,
            text: 'All of the above',
          },
        ],
      },
    },
  ],
  nonliving: [
    {
      id: 'sun',
      name: 'Sun',
      src: 'nonliving/sun.jpg',
      question: {
        text:
          'Why is the sun an important part of the Sonoran Desert ecosystem?',
        answers: [
          {
            id: 1,
            text: 'The sun makes all the water in the desert evaporate',
          },
          {
            id: 2,
            text: 'Sunlight is needed for plants to grow',
            correct: true,
          },
          {
            id: 3,
            text: 'The sun makes the desert really hot',
          },
          {
            id: 4,
            text: 'All of the above',
          },
        ],
      },
    },
    {
      id: 'water',
      name: 'Water',
      src: 'nonliving/water.jpg',
      question: {
        text: 'Why is water an important part of the Sonoran Desert ecosystem?',
        answers: [
          {
            id: 1,
            text:
              'Water creates puddles where animals can see their reflection',
          },
          {
            id: 2,
            text: 'Plants and animals need water to stay alive',
            correct: true,
          },
          {
            id: 3,
            text: 'Water cleans off dusty desert surfaces',
          },
          {
            id: 4,
            text: 'All of the above',
          },
        ],
      },
    },
    {
      id: 'dirt',
      name: 'Dirt',
      src: 'nonliving/dirt.jpg',
      question: {
        text: 'Why is dirt an important part of the Sonoran Desert ecosystem?',
        answers: [
          {
            id: 1,
            text: 'Dirt creates dust storms when it gets windy',
          },
          {
            id: 2,
            text:
              'Dirt provides the nutrients plants need to grow and survive in the desert',
            correct: true,
          },
          {
            id: 3,
            text: 'Dirt makes the desert brown',
          },
          {
            id: 4,
            text: 'All of the above',
          },
        ],
      },
    },
    {
      id: 'rocks',
      name: 'Rocks',
      src: 'nonliving/rocks.jpg',
      question: {
        text:
          'Why are rocks an important part of the Sonoran Desert ecosystem?',
        answers: [
          {
            id: 1,
            text: 'Rocks are pretty to look at',
          },
          {
            id: 2,
            text: 'Rocks break down into new soil for plants to grow in',
            correct: true,
          },
          {
            id: 3,
            text: 'Rocks are needed to create landslides',
          },
          {
            id: 4,
            text: 'All of the above',
          },
        ],
      },
    },
    {
      id: 'caves',
      name: 'Caves',
      src: 'nonliving/cave.jpg',
      question: {
        text:
          'Why are caves an important part of the Sonoran Desert ecosystem?',
        answers: [
          {
            id: 1,
            text:
              'Caves provide shelter for different animals during the hot daytime and at night',
            correct: true,
          },
          {
            id: 2,
            text: 'Caves are a hiding spot for coyotes to scare other animals',
          },
          {
            id: 3,
            text: 'Water collectes in caves when it rains',
          },
          {
            id: 4,
            text: 'All of the above',
          },
        ],
      },
    },
    {
      id: 'scat',
      name: 'Scat',
      src: 'nonliving/scat.jpg',
      question: {
        text: 'Why is scat an important part of the Sonoran Desert ecosystem?',
        answers: [
          {
            id: 1,
            text: 'Scat indicates when an animal is sick',
          },
          {
            id: 2,
            text:
              'The smell from scat is bad so it keeps all other animals away',
          },
          {
            id: 3,
            text:
              'Animals use scat to figure out what other animals might be around',
            correct: true,
          },
          {
            id: 4,
            text: 'All of the above',
          },
        ],
      },
    },
    {
      id: 'eggshells',
      name: 'Eggshells',
      src: 'nonliving/eggshells.jpg',
      question: {
        text:
          'Why are eggshells and important part of the Sonoran Desert ecosystem?',
        answers: [
          {
            id: 1,
            text: 'Some animals eat broken eggshells for the minerals',
          },
          {
            id: 2,
            text: 'The eggshells provide shelter for bugs',
          },
          {
            id: 3,
            text: 'Packrats collect the eggshells',
          },
          {
            id: 4,
            text: 'All of the above',
            correct: true,
          },
        ],
      },
    },
    {
      id: 'deadPlants',
      name: 'Dead Plants',
      src: 'nonliving/dead-plant.jpg',
      question: {
        text:
          'Why are dead plants an important part of the Sonoran Desert ecosystem?',
        answers: [
          {
            id: 1,
            text:
              'Dead plants provide food and building material for birds, rodents, and bugs',
            correct: true,
          },
          {
            id: 2,
            text:
              'Animals use dead plants to tell if there is contaminated water nearby',
          },
          {
            id: 3,
            text:
              'Javelinas roll around on dead plants to make themselves smell bad to predators',
          },
          {
            id: 4,
            text: 'All of the above',
          },
        ],
      },
    },
    {
      id: 'animalBones',
      name: 'Animal Bones',
      src: 'nonliving/animal-bones.jpg',
      question: {
        text:
          'Why are animal bones an important part of the Sonoran Desert ecosystem?',
        answers: [
          {
            id: 1,
            text: 'Animal bones mark the territory of different predators',
          },
          {
            id: 2,
            text:
              'Many animals eat bones for the nutrients, just like they eat the body',
            correct: true,
          },
          {
            id: 3,
            text: 'Coyotes play fetch with animal bones',
          },
          {
            id: 4,
            text: 'All of the above',
          },
        ],
      },
    },
    {
      id: 'desertWashes',
      name: 'Desert Washes',
      src: 'nonliving/desert-wash.jpg',
      question: {
        text:
          'Why are desert washes an important part of the Sonoran Desert ecosystem?',
        answers: [
          {
            id: 1,
            text:
              'Male mountain lions go to the desert washes to fight other males',
          },
          {
            id: 2,
            text:
              'Desert washes collect water when there are heavy rains in the desert',
            correct: true,
          },
          {
            id: 3,
            text:
              'Animals shelter in desert washes during monsoon rains when it floods',
          },
          {
            id: 4,
            text: 'All of the above',
          },
        ],
      },
    },
    {
      id: 'minerals',
      name: 'Minerals',
      src: 'nonliving/minerals.jpg',
      question: {
        text:
          'Why are minerals an important part of the Sonoran Desert ecosystem?',
        answers: [
          {
            id: 1,
            text: 'Plants use minerals in the soil as food so they can grow',
            correct: true,
          },
          {
            id: 2,
            text: `Harris's hawks use minerals to decorate their nests`,
          },
          {
            id: 3,
            text: `Minerals provide flavor for animal's food`,
          },
          {
            id: 4,
            text: 'All of the above',
          },
        ],
      },
    },
    {
      id: 'air',
      name: 'Air',
      src: 'nonliving/air.jpg',
      question: {
        text: 'Why is air an important part of the Sonoran Desert ecosystem?',
        answers: [
          {
            id: 1,
            text:
              'Air provides animals oxygen to breath and plants carbon dioxide for growth',
            correct: true,
          },
          {
            id: 2,
            text: 'Air creates weather in the desert',
          },
          {
            id: 3,
            text: 'Air determines the temperature of the desert',
          },
          {
            id: 4,
            text: 'All of the above',
          },
        ],
      },
    },
  ],
};

export default level1Data;
