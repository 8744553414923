import { IAvatar, OrganismId } from '@sonoran-ecosystems/types';

const data: IAvatar[] = [
  {
    id: OrganismId.HARRIS_HAWK,
    src: 'living/harris-hawk.jpg',
    cost: 10,
  },
  {
    id: OrganismId.ANTELOPE_SQUIRREL,
    src: 'living/antelope-squirrel.jpg',
    cost: 10,
  },
  {
    id: OrganismId.POCKET_MOUSE,
    src: 'living/pocket-mouse.jpg',
    cost: 10,
  },
  {
    id: OrganismId.HUMMINGBIRD,
    src: 'living/hummingbird.jpg',
    cost: 10,
  },
];

export default data;
