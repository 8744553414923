import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles, Paper, Button, CircularProgress } from '@material-ui/core';

import PointBadge from 'components/PointBadge';

const useStyles = makeStyles(() => ({
  paper: {
    width: '7rem',
    height: '7rem',
    padding: '8px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
}));

export interface Props {
  points: number | null;
  canPlace?: boolean;
  attemptingPlace?: boolean;
  disabled?: boolean;
  onPlace?: () => void;
}

const EmptyTile: FunctionComponent<Props> = ({
  points,
  canPlace,
  disabled,
  attemptingPlace,
  onPlace,
}) => {
  const { t } = useTranslation(['level2']);
  const classes = useStyles();

  return (
    <PointBadge points={points}>
      <Paper className={classes.paper}>
        {attemptingPlace && <CircularProgress size={24} />}
        {!attemptingPlace && canPlace ? (
          <Button variant="contained" onClick={onPlace} disabled={disabled}>
            {t('place')}
          </Button>
        ) : (
          <></>
        )}
      </Paper>
    </PointBadge>
  );
};

export default EmptyTile;
