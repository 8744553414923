import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import useLevelState from 'hooks/useLevelState';
import { makeStyles } from '@material-ui/styles';

import { Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  text: {
    textAlign: 'center',
  },
}));

const StatisticsContent: FunctionComponent = () => {
  const { t } = useTranslation(['common']);
  const classes = useStyles();
  const { level1State } = useLevelState();

  let nextLevelHelperText = 'NextLevelNotUnlocked';
  if (level1State && level1State.points >= level1State.unlockPoints) {
    nextLevelHelperText = 'NextLevelUnlocked';
  }

  return (
    <Grid container spacing={3} alignItems="center" justify="center">
      <Grid item xs={12}>
        <Typography>
          {t(nextLevelHelperText, { value: level1State?.unlockPoints })}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" className={classes.text}>
          {t('Points')}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" className={classes.text}>
          {level1State?.points || t('N/A')}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" className={classes.text}>
          {t('FirstTries')}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" className={classes.text}>
          {level1State ? level1State.firstTries : 'N/A'}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default StatisticsContent;
