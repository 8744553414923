import React, { FunctionComponent, ReactNode } from 'react';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { DraggableProvided } from 'react-beautiful-dnd';

import StandalonePointBadge from 'components/StandalonePointBadge';

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 1,
    position: 'relative',
    display: 'inline-block',
  },
  badge: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 2,
    transform: 'scale(1) translate(50%, -50%)',
    transformOrigin: '100% 0%',
  },
}));

export interface Props {
  className?: string;
  children: ReactNode;
  points: number | null;
  draggableProvided?: DraggableProvided | undefined;
}

const PointBadge: FunctionComponent<Props> = ({
  className,
  points,
  children,
  draggableProvided,
}: Props) => {
  const classes = useStyles();

  if (points == null) {
    return (
      <div
        className={clsx(classes.root, className)}
        // eslint-disable-next-line @typescript-eslint/unbound-method
        ref={draggableProvided ? draggableProvided.innerRef : undefined}
        {...(draggableProvided ? draggableProvided.dragHandleProps : [])}
        {...(draggableProvided ? draggableProvided.draggableProps : [])}
      >
        {children}
      </div>
    );
  }

  return (
    <div
      className={clsx(classes.root, className)}
      // eslint-disable-next-line @typescript-eslint/unbound-method
      ref={draggableProvided ? draggableProvided.innerRef : undefined}
      {...(draggableProvided ? draggableProvided.dragHandleProps : [])}
      {...(draggableProvided ? draggableProvided.draggableProps : [])}
    >
      {points && (
        <div className={classes.badge}>
          <StandalonePointBadge points={points} />
        </div>
      )}
      {children}
    </div>
  );
};

export default PointBadge;
