import React, { FunctionComponent, ReactNode, useState } from 'react';

import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';

import NextStepIconButton from 'components/IconButtons/NextStep';
import BackStepIconButton from 'components/IconButtons/BackStep';
import CloseIconButton from 'components/IconButtons/Close';

const useStyles = makeStyles(() => ({
  actions: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  loading: {
    marginRight: '0.5rem',
  },
}));

export interface Props {
  open: boolean;
  onClose: () => void;
  title: ReactNode;
  ContentSteps: Array<JSX.Element>;
}

const TutorialDialog: FunctionComponent<Props> = ({
  open,
  onClose,
  title,
  ContentSteps,
}) => {
  const classes = useStyles();
  const [step, setStep] = useState(0);

  let actions = null;
  if (step === 0 && ContentSteps.length !== 1) {
    actions = <NextStepIconButton onClick={() => setStep(step + 1)} />;
  } else if (step === ContentSteps.length - 1) {
    actions = (
      <>
        {ContentSteps.length !== 1 && (
          <BackStepIconButton onClick={() => setStep(step - 1)} />
        )}
        <CloseIconButton onClick={onClose} />;
      </>
    );
  } else {
    actions = (
      <>
        <BackStepIconButton onClick={() => setStep(step - 1)} />
        <NextStepIconButton onClick={() => setStep(step + 1)} />;
      </>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      disablePortal
      style={{ position: 'absolute' }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{ContentSteps[step]}</DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>{actions}</DialogActions>
    </Dialog>
  );
};

export default TutorialDialog;
