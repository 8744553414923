import { FunctionComponent, useEffect, useState, useCallback } from 'react';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import { makeStyles, Slide } from '@material-ui/core';
import LineTo from 'react-lineto';

import useLevelState from 'hooks/useLevelState';
import { ITile } from 'hooks/useLevelState/LevelStateProvider';

import SummaryDialog from 'components/Level3/SummaryDialog';
import SupportDialog from 'components/SupportDialog';
import IntroductionDialog from 'components/IntroductionDialog';
import TutorialDialog from 'components/Level3/TutorialDialog';
import AchievementsDialog from 'components/Achievements/DialogWithListener';
import HomeIconButton from 'components/IconButtons/Home';
import SubmitIconButton from 'components/IconButtons/Submit';
import LevelToolbar from 'components/LevelToolbar';
import OrganismDrawer from 'components/OrganismDrawer';
import OrganismBank from 'components/OrganismBank';
import ThingCard from 'components/ThingCard';
import EmptyTile from 'components/EmptyTile';
import SubmitHelpDialog from 'components/Level3/SubmitHelpDialog';
import SummaryIconButton from 'components/IconButtons/Summary';
import SubmitToTeacherConfirmationDialog from 'components/Level3/SubmitToTeacherConfirmationDialog';
import LevelLoading from 'components/LevelLoading';

// Types
import ILivingThing from 'types/interfaces/ILivingThing';
import LevelStatus from 'types/enums/LevelStatus';

// Utils
import { log } from 'utils/log';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
  },
  toolbarContainer: {
    position: 'absolute',
    top: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '2rem',
    zIndex: 1201,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconButton: {
    marginLeft: '1rem',
  },
  content: {
    flex: 1,
    maxWidth: '1200px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    '@media (min-width: 1920px)': {
      maxWidth: '1700px',
    },
  },
  notPlaced: {
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
  },
  circleContainer: {
    marginLeft: '150px',
    width: '38.25rem',
    height: '38.25rem',
    position: 'relative',
    '@media (min-width: 1920px)': {
      marginTop: '20px',
      marginLeft: '200px',
      width: '50rem',
      height: '50rem',
    },
  },
  drawerContainer: {
    marginTop: '40px',
  },
  tile: {
    display: 'block',
    position: 'absolute',
    top: '50%',
    left: '50%',
    margin: '-3.125rem',
    zIndex: 1,
  },
  submit: {
    marginLeft: '1rem',
  },
  tile0: {
    transform: 'translate(16rem)',
    '@media (min-width: 1920px)': {
      transform: 'translate(20rem)',
    },
  },
  tile1: {
    transform: 'rotate(50deg) translate(16rem) rotate(-50deg)',
    '@media (min-width: 1920px)': {
      transform: 'rotate(50deg) translate(20rem) rotate(-50deg)',
    },
  },
  tile2: {
    transform: 'rotate(100deg) translate(16rem) rotate(-100deg)',
    '@media (min-width: 1920px)': {
      transform: 'rotate(100deg) translate(20rem) rotate(-100deg)',
    },
  },
  tile3: {
    transform: 'rotate(150deg) translate(16rem) rotate(-150deg)',
    '@media (min-width: 1920px)': {
      transform: 'rotate(150deg) translate(20rem) rotate(-150deg)',
    },
  },
  tile4: {
    transform: 'rotate(200deg) translate(16rem) rotate(-200deg)',
    '@media (min-width: 1920px)': {
      transform: 'rotate(200deg) translate(20rem) rotate(-200deg)',
    },
  },
  tile5: {
    transform: 'rotate(250deg) translate(16rem) rotate(-250deg)',
    '@media (min-width: 1920px)': {
      transform: 'rotate(250deg) translate(20rem) rotate(-250deg)',
    },
  },
  tile6: {
    transform: 'rotate(305deg) translate(16rem) rotate(-305deg)',
    '@media (min-width: 1920px)': {
      transform: 'rotate(305deg) translate(20rem) rotate(-305deg)',
    },
  },
  line: {
    position: 'relative',
  },
  hidden: {
    display: 'none',
  },
}));

const Level3: FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation(['level3']);
  const [attemptingTilePlace, setAttemptingTilePlace] = useState<number>();
  const [attemptingRemove, setAttemptingRemove] = useState(false);
  const [triggeredSubmitHelpDialog, setTriggeredSubmitHelpDialog] = useState(
    false,
  );
  const [openSubmitHelpDialog, setOpenSubmitHelpDialog] = useState(false);
  const [openIntroductionDialog, setOpenIntroductionDialog] = useState(false);
  const [openSummaryDialog, setOpenSummaryDialog] = useState(false);
  const [openSupportDialog, setOpenSupportDialog] = useState(false);
  const [openAchievementDialog, setOpenAchievementDialog] = useState(false);
  const [openTutorialDialog, setOpenTutorialDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [, setOpenSummaryDialogTriggered] = useState(false);
  const [selectedTileId, setSelectedTileId] = useState<number | undefined>();
  const [organism, setOrganism] = useState<ILivingThing | null>(null);
  const [nextSelectedTileId, setNextSelectedTileId] = useState<
    number | undefined
  >();
  const [nextOrganism, setNextOrganism] = useState<ILivingThing | null>(null);
  const [canConnect, setCanConnect] = useState(false);
  const [canPlace, setCanPlace] = useState(false);
  const [drawerActive, setDrawerActive] = useState(false);
  const [bankActive, setBankActive] = useState(true);
  const {
    level3State,
    initializeLevel,
    currentLevel,
    currentLevelInitialized,
    setCurrentLevel,
    level3ConnectTiles,
    level3DisconnectTiles,
    level3PlaceOrganismInTile,
    level3RemoveOrganismFromTile,
    level3SubmitToTeacher,
  } = useLevelState();

  useEffect(() => {}, []);

  useEffect(() => {
    if (currentLevel !== 3) {
      setCurrentLevel(3);
    }

    initializeLevel().catch(log);
  }, [currentLevel, setCurrentLevel, initializeLevel]);

  const handleCloseDrawer = useCallback(() => {
    setCanPlace(false);
    setDrawerActive(false);
  }, []);

  useEffect(() => {
    // Show submit help dialog if they haven't seen it before.
    if (triggeredSubmitHelpDialog) {
      return;
    }

    if (!level3State?.notPlaced) {
      return;
    }

    if (level3State.notPlaced.length > 5) {
      return;
    }

    setOpenSubmitHelpDialog(true);
    setTriggeredSubmitHelpDialog(true);
  }, [level3State, triggeredSubmitHelpDialog]);

  useEffect(() => {
    // Checks if the organism was placed, and if so it will close the drawer
    if (!canPlace || !organism || !level3State) {
      return;
    }

    if (level3State.notPlaced.find((item) => item.id === organism.id)) {
      return;
    }

    handleCloseDrawer();
  }, [handleCloseDrawer, canPlace, level3State, organism, bankActive]);

  useEffect(() => {
    // reset state if new level
    if (level3State?.status === LevelStatus.START) {
      setOpenIntroductionDialog(true);
      setOpenSummaryDialog(false);
      setOpenSummaryDialogTriggered(false);
      setSelectedTileId(undefined);
      setOrganism(null);
      setDrawerActive(false);
    }
  }, [level3State]);

  const handleAchievementClick = useCallback(() => {
    setOpenAchievementDialog(true);
  }, []);

  const handleSupportClick = useCallback(() => {
    setOpenSupportDialog(true);
  }, []);

  const handleHomeClick = useCallback(() => {
    history.push('/select-level');
  }, [history]);

  const handleCloseIntroduction = useCallback(() => {
    setOpenIntroductionDialog(false);
    setOpenTutorialDialog(true);
  }, []);

  const handleCloseTutorial = useCallback(() => {
    setOpenTutorialDialog(false);
  }, []);

  const handleClickNotPlaced = useCallback(
    (notPlacedOrganism: ILivingThing) => {
      setCanPlace(true);
      setBankActive(false);
      setOrganism(notPlacedOrganism);
    },
    [],
  );

  const handleClickPlaced = useCallback(
    (tile: ITile) => {
      setCanConnect(true);

      if (organism && (selectedTileId || selectedTileId === 0)) {
        // assume the drawer is already open and needs replaced
        setDrawerActive(false);
        setNextOrganism(tile.placedOrganism);
        setNextSelectedTileId(tile.id);
      } else {
        // assume bank is active
        setBankActive(false);
        setSelectedTileId(tile.id);
        setOrganism(tile.placedOrganism || null);
      }
    },
    [selectedTileId, organism],
  );

  const handleBankExited = useCallback(() => {
    setDrawerActive(true);
  }, []);

  const handleDrawerExited = useCallback(() => {
    if (nextOrganism && (nextSelectedTileId || nextSelectedTileId === 0)) {
      // replace organism in drawer with next organism
      setDrawerActive(true);
      setOrganism(nextOrganism);
      setSelectedTileId(nextSelectedTileId);
      setNextOrganism(null);
      setNextSelectedTileId(undefined);
    } else {
      setCanConnect(false);
      setBankActive(true);
      // if you remove this, then the place button will not show on the next drawer open for a non placed
      // because a useEffect is there to check when to close the drawer if you place the not placed
      // and without this it creates a "race condition" (it doesn't actually race, it always messes up)
      setOrganism(null);
      setSelectedTileId(undefined);
    }
  }, [nextOrganism, nextSelectedTileId]);

  const handlePlace = useCallback(
    async (tileId: number) => {
      if (!organism) {
        return;
      }

      setAttemptingTilePlace(tileId);
      await level3PlaceOrganismInTile(organism.id, tileId);
      setAttemptingTilePlace(undefined);
    },
    [organism, level3PlaceOrganismInTile],
  );

  const handleRemove = useCallback(async () => {
    if (selectedTileId == null) {
      return;
    }

    setAttemptingRemove(true);
    await level3RemoveOrganismFromTile(selectedTileId);
    setAttemptingRemove(false);
    setDrawerActive(false);
  }, [selectedTileId, level3RemoveOrganismFromTile]);

  const handleSubmitToTeacher = useCallback(async () => {
    await level3SubmitToTeacher();
    setOpenConfirmDialog(false);
  }, [level3SubmitToTeacher]);

  const handleConnect = useCallback(
    async (tileId: number) => {
      if (canConnect && selectedTileId !== undefined) {
        await level3ConnectTiles(selectedTileId, tileId);
      }
    },
    [level3ConnectTiles, selectedTileId, canConnect],
  );

  const handleDisconnect = useCallback(
    async (tileId: number) => {
      if (canConnect && selectedTileId !== undefined) {
        await level3DisconnectTiles(selectedTileId, tileId);
      }
    },
    [level3DisconnectTiles, selectedTileId, canConnect],
  );

  function getTileClassName(id: number): string | undefined {
    switch (id) {
      case 0:
        return classes.tile0;
      case 1:
        return classes.tile1;
      case 2:
        return classes.tile2;
      case 3:
        return classes.tile3;
      case 4:
        return classes.tile4;
      case 5:
        return classes.tile5;
      case 6:
        return classes.tile6;
      default:
        return undefined;
    }
  }

  if (!currentLevelInitialized) {
    return <LevelLoading />;
  }

  if (!level3State) {
    return <></>;
  }

  const {
    submitted,
    notPlaced,
    tiles,
    tileConnections,
    canSubmit,
    disabled,
  } = level3State;

  return (
    <>
      <div className={classes.root}>
        <div className={classes.toolbarContainer}>
          <div className={classes.buttonContainer}>
            <HomeIconButton onClick={handleHomeClick} />
            {canSubmit && (
              <SubmitIconButton
                disabled={disabled}
                className={classes.submit}
                onClick={() => setOpenConfirmDialog(true)}
              />
            )}
            {Boolean(submitted) && (
              <SummaryIconButton
                className={classes.iconButton}
                onClick={() => setOpenSummaryDialog(true)}
              />
            )}
          </div>
          <LevelToolbar
            hidePoints
            onAchievementClick={handleAchievementClick}
            onSupportClick={handleSupportClick}
          />
        </div>
        <div className={classes.content}>
          <div className={classes.circleContainer}>
            {tileConnections.map((connection) => {
              const from = getTileClassName(connection.indexes[0]);
              const to = getTileClassName(connection.indexes[1]);

              if (!from || !to) {
                return <></>;
              }

              const anchors = connection.indexes.map((value) => {
                const anchor = [];
                // handle left or right
                if ([0, 1, 6].includes(value)) {
                  anchor.push('left');
                } else if ([2, 5].includes(value)) {
                  anchor.push('center');
                } else {
                  anchor.push('right');
                }

                // handle top or bottom
                if ([5, 6].includes(value)) {
                  anchor.push('bottom');
                } else if ([1, 2, 3].includes(value)) {
                  anchor.push('top');
                } else {
                  anchor.push('middle');
                }

                return anchor.join(' ');
              });

              return (
                <LineTo
                  className={classes.line}
                  fromAnchor={anchors[0]}
                  toAnchor={anchors[1]}
                  delay
                  borderWidth={4}
                  borderColor="black"
                  key={connection.indexes.join(':')}
                  from={from}
                  to={to}
                  within={classes.circleContainer}
                />
              );
            })}
            {tiles.map((tile) => {
              const className = getTileClassName(tile.id);
              if (!tile.placedOrganism) {
                const attemptingPlace =
                  attemptingTilePlace || attemptingTilePlace === 0
                    ? attemptingTilePlace === tile.id
                    : false;
                const tileDisabled = Boolean(
                  (attemptingTilePlace || attemptingTilePlace === 0) &&
                    !attemptingPlace,
                );

                return (
                  <div key={tile.id} className={clsx(className, classes.tile)}>
                    <EmptyTile
                      disabled={disabled || tileDisabled}
                      attemptingPlace={attemptingPlace}
                      points={null}
                      canPlace={canPlace}
                      onPlace={() => handlePlace(tile.id)}
                    />
                  </div>
                );
              }

              let onButtonClick;
              let buttonText;
              if (canConnect && selectedTileId !== tile.id) {
                let tileConnection;
                if (selectedTileId || selectedTileId === 0) {
                  tileConnection = tileConnections.find(
                    (item) =>
                      item.indexes.includes(selectedTileId) &&
                      item.indexes.includes(tile.id),
                  );
                }

                if (tileConnection) {
                  onButtonClick = () => handleDisconnect(tile.id);
                  buttonText = t('disconnect');
                } else {
                  onButtonClick = () => handleConnect(tile.id);
                  buttonText = t('connect');
                }
              }

              return (
                <div key={tile.id} className={clsx(className, classes.tile)}>
                  <ThingCard
                    small
                    points={null}
                    name={tile.placedOrganism.name}
                    src={tile.placedOrganism.src}
                    disableMotion={disabled}
                    onClick={
                      disabled ? undefined : () => handleClickPlaced(tile)
                    }
                    onButtonClick={disabled ? undefined : onButtonClick}
                    buttonText={disabled ? undefined : buttonText}
                  />
                </div>
              );
            })}
          </div>
          <div className={classes.drawerContainer}>
            <Slide
              direction="left"
              in={bankActive}
              mountOnEnter
              unmountOnExit
              onExited={handleBankExited}
            >
              <OrganismBank>
                {notPlaced.map((notPlacedOrganism) => (
                  <ThingCard
                    points={null}
                    className={classes.notPlaced}
                    key={notPlacedOrganism.id}
                    name={notPlacedOrganism.name}
                    src={notPlacedOrganism.src}
                    disableMotion={disabled}
                    onClick={
                      disabled
                        ? undefined
                        : () => handleClickNotPlaced(notPlacedOrganism)
                    }
                  />
                ))}
              </OrganismBank>
            </Slide>
            <Slide
              direction="left"
              in={drawerActive}
              mountOnEnter
              unmountOnExit
              onExited={handleDrawerExited}
            >
              <OrganismDrawer
                loading={attemptingRemove}
                organism={organism}
                onClose={handleCloseDrawer}
                onRemove={
                  disabled || selectedTileId == null ? undefined : handleRemove
                }
              />
            </Slide>
          </div>
        </div>
        <IntroductionDialog
          title={t('IntroductionTitle')}
          content={t('IntroductionContent')}
          open={openIntroductionDialog}
          onClose={handleCloseIntroduction}
        />
        <SummaryDialog
          open={openSummaryDialog}
          onClose={() => setOpenSummaryDialog(false)}
        />
        <SubmitHelpDialog
          open={openSubmitHelpDialog}
          onClose={() => setOpenSubmitHelpDialog(false)}
        />
        <SubmitToTeacherConfirmationDialog
          open={openConfirmDialog}
          onClose={() => setOpenConfirmDialog(false)}
          onSubmit={handleSubmitToTeacher}
        />
        <SupportDialog
          open={openSupportDialog}
          level={3}
          onClose={() => setOpenSupportDialog(false)}
          title="Help"
        />
        <AchievementsDialog
          open={openAchievementDialog}
          onClose={() => setOpenAchievementDialog(false)}
        />
        <TutorialDialog
          open={openTutorialDialog}
          onClose={handleCloseTutorial}
        />
      </div>
    </>
  );
};

export default Level3;
