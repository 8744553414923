import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/analytics';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDnpjO5qvylBul5K0OAMunzkyE0JdBz74A',
  authDomain: 'mcdowell-sonoran.firebaseapp.com',
  projectId: 'mcdowell-sonoran',
  storageBucket: 'mcdowell-sonoran.appspot.com',
  messagingSenderId: '147936304408',
  appId: '1:147936304408:web:e106c3837b8458b8a5526d',
  measurementId: 'G-1HNC42P272',
};

const app = firebase.initializeApp(firebaseConfig);
export const analytics = firebase.analytics();
export const database = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const functions = firebase.functions();

if (process.env.NODE_ENV === 'development') {
  database.useEmulator('localhost', 8080);
  auth.useEmulator('http://localhost:9099');
  functions.useEmulator('localhost', 5001);
}

export default app;
