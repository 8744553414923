import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import SummaryDialog from 'components/SummaryDialog';

import { Typography } from '@material-ui/core';
import StatisticsContent from './StatisticsContent';

export interface Props {
  open: boolean;
  loading?: boolean;
  onClose: () => void;
  onRestart: () => void;
}

const ContentStep0: FunctionComponent = () => {
  const { t } = useTranslation(['level1']);
  return <Typography>{t('Summary')}</Typography>;
};

const Level1SummaryDialog: FunctionComponent<Props> = ({
  open,
  loading,
  onClose,
  onRestart,
}) => {
  const { t } = useTranslation(['level1']);

  return (
    <SummaryDialog
      title={t('Level1Summary')}
      ContentStep0={ContentStep0}
      ContentStep1={StatisticsContent}
      open={open}
      loading={loading}
      onClose={onClose}
      onClickRestart={onRestart}
    />
  );
};

export default Level1SummaryDialog;
