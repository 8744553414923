import { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    height: '100%',
  },
  header: {
    padding: '2rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerRight: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  children: {
    padding: '1rem',
  },
}));

interface Props {
  children: ReactNode;
  headerRightChildren?: ReactNode;
}

const MenuBackground: FunctionComponent<Props> = ({
  headerRightChildren,
  children,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h5">{t('WebOfLife')}</Typography>
        <div className={classes.headerRight}>{headerRightChildren}</div>
      </div>
      {children}
    </div>
  );
};

export default MenuBackground;
