import { FunctionComponent, useEffect, useState } from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import { useCountUp } from 'react-countup';

const useStyles = makeStyles(() => ({
  badge: {
    backgroundColor: '#C22026',
    color: 'white',
    height: '2.75rem',
    width: '2.75rem',
    borderRadius: '1.375rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export interface Props {
  points: number;
}

const StandalonePointBadge: FunctionComponent<Props> = ({ points }) => {
  const classes = useStyles();
  const [triggerAnimation, setTriggerAnimation] = useState(false);
  const { countUp, update } = useCountUp({
    start: points,
    end: points,
    duration: 2,
  });

  useEffect(() => {
    if (!points) {
      return;
    }

    setTriggerAnimation(true);
  }, [points]);

  useEffect(() => {
    if (!triggerAnimation) {
      return;
    }

    setTriggerAnimation(false);
    update(points);
  }, [points, update, triggerAnimation]);

  return (
    <div className={classes.badge}>
      <Typography variant="h6">{countUp}</Typography>
    </div>
  );
};

export default StandalonePointBadge;
