import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';

import SummaryDialog from 'components/SummaryDialog';

import StatisticsContent from './StatisticsContent';

export interface Props {
  open: boolean;
  loading?: boolean;
  onClose: () => void;
  onRestart: () => void;
}

const ContentStep0: FunctionComponent = () => {
  const { t } = useTranslation(['level2']);
  return <Typography>{t('Summary')}</Typography>;
};

const Level2SummaryDialog: FunctionComponent<Props> = ({
  open,
  onClose,
  onRestart,
  loading,
}) => {
  const { t } = useTranslation(['level2']);

  return (
    <SummaryDialog
      title={t('Level2Summary')}
      loading={loading}
      ContentStep0={ContentStep0}
      ContentStep1={StatisticsContent}
      open={open}
      onClose={onClose}
      onClickRestart={onRestart}
    />
  );
};

export default Level2SummaryDialog;
