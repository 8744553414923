import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  makeStyles,
} from '@material-ui/core';

import SubmitIconButton from 'components/IconButtons/Submit';

const useStyles = makeStyles(() => ({
  showcase: {
    marginTop: '1rem',
    display: 'inline-flex',
    flexDirection: 'row',
  },
  buttonHelp: {
    marginLeft: '1rem',
  },
}));

export interface Props {
  open: boolean;
  onClose: () => void;
}

const SubmitHelpDialog: FunctionComponent<Props> = ({ open, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation(['level3']);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('SubmitToTeacher')}</DialogTitle>
      <DialogContent>
        <Typography>{t('SubmitToTeacherHelp')}</Typography>
        <div className={classes.showcase}>
          <SubmitIconButton />
          <Typography className={classes.buttonHelp}>
            {t('SubmitToTeacherHelpButton')}
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubmitHelpDialog;
