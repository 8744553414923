import React, { FunctionComponent } from 'react';

import useSound from 'use-sound';

import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@material-ui/core';
import { RestartIcon } from 'utils/iconMappings';

export interface Props {
  className?: string | undefined;
  onClick: () => void;
  disabled?: boolean;
}

const RestartIconButton: FunctionComponent<Props> = ({
  className,
  onClick,
  disabled,
}: Props) => {
  const { t } = useTranslation('common');
  const [play] = useSound(`${process.env.PUBLIC_URL}/sounds/click.mp3`, {
    interrupt: true,
  });

  function handleClick(): void {
    play();
    onClick();
  }

  return (
    <Tooltip title={`${t('Restart')}`}>
      <IconButton
        disabled={disabled}
        className={className}
        onClick={handleClick}
        color="primary"
      >
        <RestartIcon />
      </IconButton>
    </Tooltip>
  );
};

export default RestartIconButton;
