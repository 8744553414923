import React, { FunctionComponent, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { useCountUp } from 'react-countup';
import { motion, useAnimation } from 'framer-motion';
import {
  Avatar,
  Paper,
  Typography,
  LinearProgress,
  Divider,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { AvatarStatus, IAvatar } from '@sonoran-ecosystems/types';

import { auth, storage, database } from 'firebaseApp';
import { log } from 'utils/log';
import staticAvatarData from 'data/avatar';

// Components
import SupportIconButton from '../IconButtons/Support';
import AchievementIconButton from '../IconButtons/Achievement';

const useStyles = makeStyles(() => ({
  root: {
    padding: '0.5rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  kovach: {
    width: '100%',
    height: '64px',
  },
  progressPointsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  pointsContainer: {
    minWidth: '250px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  marginLeftSeperator: {
    marginLeft: '1rem',
  },
  avatar: {
    width: '48px',
    height: '48px',
  },
  progressRoot: {
    borderRadius: '0.4rem',
    height: '0.8rem',
    backgroundColor: '#eff1f8',
  },
  progressContainer: {
    position: 'relative',
  },
  bar1Determinate: {
    background: 'linear-gradient(180deg, #CE4C51 0%, #C22024 100%)',
    borderRadius: '0.4rem',
    bottom: 1,
    // boxShadow: '0px 4px 8px rgba(194, 32, 36, 0.35)',
  },
  unlockIndicator: {
    position: 'absolute',
    top: '0',
    backgroundColor: 'black',
  },
}));

export interface Props {
  points?: number;
  maxPoints?: number;
  unlockPoints?: number;
  hidePoints?: boolean;
  supportDialog?: boolean;
  onSupportClick?: () => void;
  onAchievementClick?: () => void;
  displayOnly?: boolean;
}

const LevelToolbar: FunctionComponent<Props> = ({
  points,
  maxPoints,
  unlockPoints,
  hidePoints,
  supportDialog,
  onSupportClick,
  onAchievementClick,
  displayOnly,
}: Props) => {
  const classes = useStyles();
  const [triggerAnimation, setTriggerAnimation] = useState(false);
  const { t } = useTranslation('levelToolbar');
  const [avatar, setAvatar] = useState<IAvatar | undefined>();
  const [avatarSrc, setAvatarSrc] = useState<string | undefined>();
  const controls = useAnimation();

  const { countUp, update } = useCountUp({
    start: 0,
    end: 0,
    duration: 2,
  });

  const progress = useMemo(() => {
    if (!countUp || !maxPoints) {
      return 0;
    }

    return Math.floor((Number(countUp) * 100) / maxPoints);
  }, [countUp, maxPoints]);

  useEffect(() => {
    const uid = auth.currentUser?.uid;
    if (!uid) {
      return undefined;
    }

    const unsubscribe = database
      .collection('students')
      .doc(uid)
      .collection('avatars')
      .where('status', '==', AvatarStatus.ACTIVE)
      .onSnapshot((snapshot) => {
        if (snapshot.empty) {
          setAvatar(undefined);
          return;
        }

        const avatarId = snapshot.docs[0].id;
        setAvatar(staticAvatarData.find((item) => item.id === avatarId));
      });

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (!avatar) {
      return;
    }

    async function execute(): Promise<void> {
      if (!avatar) {
        return;
      }

      let downloadUrl;
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        downloadUrl = await storage.ref(avatar.src).getDownloadURL();
      } catch (error) {
        log(error);
      }

      setAvatarSrc(downloadUrl);
    }

    execute().catch(log);
  }, [avatar]);

  useEffect(() => {
    if (!points && points !== 0) {
      return;
    }

    setTriggerAnimation(true);
  }, [points]);

  useEffect(() => {
    if (!triggerAnimation) {
      return;
    }

    setTriggerAnimation(false);
    update(points);

    if (!displayOnly) {
      controls
        .start({
          scale: [1, 1.05, 1],
          transformOrigin: 'center',
          transition: {
            ease: 'easeInOut',
            duration: 1,
            repeat: 2,
          },
        })
        .catch((error) => {
          log(error);
        });
    }
  }, [triggerAnimation, displayOnly, points, update, controls]);

  return (
    <motion.div animate={controls}>
      <Paper className={classes.root}>
        {!hidePoints && unlockPoints && maxPoints && (
          <Box
            minWidth={supportDialog ? '125px' : 0}
            className={classes.progressPointsContainer}
          >
            {!supportDialog && (
              <div className={classes.pointsContainer}>
                <Typography variant="subtitle2">
                  {t('ValuePoints', { value: countUp })}
                </Typography>
                <Typography variant="subtitle2">
                  {t('ValuePoints', { value: maxPoints })}
                </Typography>
              </div>
            )}
            <div className={classes.progressContainer}>
              <LinearProgress
                value={progress}
                variant="determinate"
                classes={{
                  root: classes.progressRoot,
                  bar1Determinate: classes.bar1Determinate,
                }}
              />
              <Divider
                className={classes.unlockIndicator}
                style={{
                  left: `${Math.floor((unlockPoints * 100) / maxPoints)}%`,
                }}
                orientation="vertical"
              />
            </div>
          </Box>
        )}
        {!hidePoints && !displayOnly && (
          <Divider
            orientation="vertical"
            flexItem
            className={classes.marginLeftSeperator}
          />
        )}
        {onSupportClick && (
          <SupportIconButton
            className={clsx(!hidePoints && classes.marginLeftSeperator)}
            onClick={onSupportClick}
          />
        )}
        {onAchievementClick && (
          <AchievementIconButton
            className={classes.marginLeftSeperator}
            onClick={onAchievementClick}
          />
        )}
        {!displayOnly && (
          <Avatar
            className={clsx(classes.avatar, classes.marginLeftSeperator)}
            src={avatarSrc}
          />
        )}
      </Paper>
    </motion.div>
  );
};

export default LevelToolbar;
