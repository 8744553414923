import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import useSound from 'use-sound';
import { IconButton, Tooltip } from '@material-ui/core';

import { AchievementIcon } from 'utils/iconMappings';

export interface Props {
  className?: string | undefined;
  onClick: () => void;
}

const AchievementIconButton: FunctionComponent<Props> = ({
  className,
  onClick,
}: Props) => {
  const [play] = useSound(`${process.env.PUBLIC_URL}/sounds/click.mp3`, {
    interrupt: true,
  });

  const { t } = useTranslation('common');

  function handleClick(): void {
    play();
    onClick();
  }

  return (
    <Tooltip title={`${t('Achievements')}`}>
      <IconButton className={className} onClick={handleClick} color="primary">
        <AchievementIcon />
      </IconButton>
    </Tooltip>
  );
};

export default AchievementIconButton;
