import { ReactElement, useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { differenceWith } from 'lodash';
import { plainToClass } from 'class-transformer';

import CactusImage from 'assets/cactus.jpg';

import {
  Snackbar,
  SnackbarContent,
  IconButton,
  makeStyles,
  Box,
  Typography,
} from '@material-ui/core';

import {
  AchievementName,
  AchievementStatus,
  UserAchievement,
} from '@sonoran-ecosystems/types';

import { database, auth, functions } from 'firebaseApp';
import achievements from 'data/achievements';

import { log } from 'utils/log';
import { CloseIcon } from 'utils/iconMappings';

import Dialog from '../Dialog';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
  },
  anchorOriginTopCenter: {
    top: 0,
  },
  close: {
    padding: '0.5rem',
    color: 'black',
  },
  snackbar: {
    background: '#d4b680',
  },
  cactus: {
    width: '20px',
    height: '30px',
  },
  text: {
    color: 'black',
  },
}));

export interface Props {
  open: boolean;
  onClose: () => void;
}

const DialogWithListener = ({ open, onClose }: Props): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation('achievement');
  const [triggerCompare, setTriggerCompare] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string | undefined>();
  const [snackbarMessages, setSnackbarMessages] = useState<string[]>([]);
  const [nextUserAchievements, setNextUserAchievements] = useState<
    UserAchievement[] | undefined
  >(undefined);
  const [userAchievements, setUserAchievements] = useState<
    UserAchievement[] | undefined
  >(undefined);

  useEffect(() => {
    const uid = auth.currentUser?.uid;
    if (!uid) {
      return undefined;
    }

    const unsubscribe = database
      .collection('students')
      .doc(uid)
      .collection('achievements')
      .onSnapshot((snapshot) => {
        const next = snapshot.docs.map((item) =>
          plainToClass(UserAchievement, { ...item.data(), id: item.id }),
        );

        setNextUserAchievements(next);
        setTriggerCompare(true);
      });

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (!triggerCompare) {
      return;
    }

    setTriggerCompare(false);
    setUserAchievements(nextUserAchievements);

    // don't compare if the user achievements havent been set yet
    // otherwise it will show achievements unlocked on load!
    if (!userAchievements) {
      return;
    }

    const diff = differenceWith(
      nextUserAchievements,
      userAchievements,
      (obj, other) => obj.id === other.id && obj.status === other.status,
    );

    if (diff.length > 0) {
      setSnackbarMessages((prev) => [
        ...prev,
        ...diff
          .filter((item) => item.status === AchievementStatus.UNCLAIMED)
          .map((item) => item.id),
      ]);
    }
  }, [t, triggerCompare, nextUserAchievements, userAchievements]);

  useEffect(() => {
    if (snackbarMessages.length > 0 && !snackbarMessage) {
      setSnackbarMessage(snackbarMessages[0]);
      setSnackbarMessages((prev) => prev.slice(1));
      setOpenSnackbar(true);
    } else if (snackbarMessages.length && snackbarMessage && openSnackbar) {
      setOpenSnackbar(false);
    }
  }, [snackbarMessages, snackbarMessage, openSnackbar]);

  function handleClose(event: unknown, reason: string): void {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  }

  function handleExited(): void {
    setSnackbarMessage(undefined);
  }

  const handleClaimAchievement = useCallback(async (id: AchievementName) => {
    const callable = functions.httpsCallable('claimAchievement');
    try {
      await callable(id);
    } catch (error) {
      log(error);
    }
  }, []);

  return (
    <>
      {userAchievements && (
        <Dialog
          open={open}
          onClose={onClose}
          achievements={achievements}
          userAchievements={userAchievements}
          onClaimAchievement={handleClaimAchievement}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        classes={{
          root: classes.root,
          anchorOriginTopCenter: classes.anchorOriginTopCenter,
        }}
        onExited={handleExited}
        autoHideDuration={6000}
        open={openSnackbar}
        onClose={handleClose}
      >
        <SnackbarContent
          className={classes.snackbar}
          message={
            <Box display="flex" alignItems="center">
              <img src={CactusImage} alt="" className={classes.cactus} />
              <Box ml={3}>
                <Typography variant="h6" className={classes.text}>
                  {snackbarMessage
                    ? t(`Completed.${snackbarMessage}`)
                    : undefined}
                </Typography>
              </Box>
            </Box>
          }
          action={
            <IconButton
              className={classes.close}
              aria-label="close"
              color="inherit"
              onClick={() => setOpenSnackbar(false)}
            >
              <CloseIcon />
            </IconButton>
          }
        />
      </Snackbar>
    </>
  );
};

export default DialogWithListener;
