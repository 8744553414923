import React, { FunctionComponent, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, Typography, makeStyles } from '@material-ui/core';
import { Droppable } from 'react-beautiful-dnd';

import DroppableId from 'types/enums/DroppableId';

import ScrollView from '../../ScrollView';

const useStyles = makeStyles(() => ({
  root: {
    width: '300px',
    height: '550px',
    '@media (min-width: 1920px)': {
      height: '800px',
    },
  },
  headerRoot: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  divider: {
    marginTop: '0.5rem',
    width: '40px',
  },
  dividerRoot: {
    backgroundColor: '#C4C4C4',
  },
}));

export interface Props {
  living: boolean;
  children: ReactNode;
}

const Level1ScrollView: FunctionComponent<Props> = ({
  living,
  children,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation(['level1']);

  const droppableId = useMemo(() => {
    if (living) {
      return DroppableId.LEVEL1_SCROLL_VIEW_LIVING;
    }

    return DroppableId.LEVEL1_SCROLL_VIEW_NONLIVING;
  }, [living]);

  return (
    <Droppable droppableId={droppableId}>
      {(provided) => (
        <ScrollView
          className={classes.root}
          header={
            <div className={classes.headerRoot}>
              <Typography align="center" variant="h4" component="h4">
                {living ? t('Living') : t('Nonliving')}
              </Typography>
              <div className={classes.divider}>
                <Divider
                  classes={{
                    root: classes.dividerRoot,
                  }}
                />
              </div>
            </div>
          }
          droppableProvided={provided}
        >
          {children}
        </ScrollView>
      )}
    </Droppable>
  );
};

export default Level1ScrollView;
